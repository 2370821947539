import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    rootLargeScreen: {},
    generalLayout: {},
    mainArea: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    graphs: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sliders: {
      width: '100%',
      marginTop: theme.spacing(1),
      justifyContent: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      // Header title
      flexGrow: 1,
    },
    titleContainer: {
      // Sound track title container
    },
    listContainer: {
      width: '100%',
      paddingRight: theme.spacing(1),
      paddingBottom: theme.spacing(4),
      overflowX: 'hidden',
    },
    listRoot: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    panelRoot: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    inline: {
      display: 'inline',
    },
    button: {
      margin: theme.spacing(1),
      width: 10,
      top: -8,
    },
    buttonNarrow: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginHorizontal: 0,
      width: 10,
      top: -8,
    },
    buttonLight: {
      margin: theme.spacing(1),
      width: 10,
      top: -8,
      color: theme.palette.secondary.light,
    },
    buttonDisabled: {
      margin: theme.spacing(1),
      width: 10,
      top: -8,
      color: theme.palette.grey['300'],
    },
    buttonLarge: {
      width: 24,
    },
    row: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
    fabContainer: {
      height: 1,
      float: 'right',
      alignSelf: 'end',
      overflow: 'visible',
      zIndex: 1,
    },
    fab: {
      bottom: theme.spacing(4),
      right: theme.spacing(2),
    },
    fabAddAudiogram: {
      position: 'fixed',
      right: theme.spacing(2),
      zIndex: 1,
    },
    fabHome: {
      display: '',
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2) * 5,
      zIndex: 1,
    },
    fabRecord: {
      display: '',
      marginRight: theme.spacing(1),
      zIndex: 1,
    },
    fabPlay: {
      alignContent: 'center',
      alignItems: 'center',
      top: 4,
      zIndex: 1,
    },
    playBtnContainer: {
      maxWidth: '20%',
    },
    playBtn: {
      width: 220,
    },
    graphContainer: {
      marginTop: 50,
      textAlign: 'center',
    },
    hidden: {
      display: 'none',
    },
    textField: {
      // important required to prevent prod minimisation strangeness
      marginLeft: theme.spacing(2) + 'px!important',
      marginRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      width: '92%',
      backgroundColor: 'white',
    },
    secondaryText: {
      color: 'grey',
      fontStyle: 'italic',
    },
    editContainer: {
      flexDirection: 'column',
    },
    sliderFreq: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(4),
      width: '92%',
    },
    sliderLoss: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      width: '92%',
    },
    sliderTitle: {
      marginTop: theme.spacing(2) * -1,
      marginLeft: theme.spacing(2),
      width: '50%',
      fontStyle: 'italic',
    },
    qualitySliderTitle: {
      marginBottom: theme.spacing(3) * -1,
      marginLeft: theme.spacing(2),
      color: 'grey',
      fontStyle: 'italic',
      fontSize: '12px',
    },
    selectContainer: {
      width: '100%',
    },
    selectAudiogram: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(3),
      marginLeft: theme.spacing(2),
      width: '90%',
      backgroundColor: theme.palette.primary.main,
    },
    exportAudiogram: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      marginLeft: theme.spacing(4),
      width: '90%',
      'overflow-wrap': 'break-word',
    },
    importBox: {
      width: '100%',
    },
    toggleContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '20%',
      marginTop: -6,
      alignItems: 'flex-end',
    },
    toggleText: {
      width: 'max-content',
      marginTop: -1 * theme.spacing(4),
      fontSize: '12px',
      color: theme.palette.text.disabled,
      paddingLeft: theme.spacing(1),
      textAlign: 'end',
    },
    receiveContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    recordContainer: {
      width: 200,
    },
    legend: {
      display: 'flex',
      alignItems: 'flex-start',
      fontSize: 'smaller',
      fontWeight: 'lighter',
      fontStyle: 'italic',
    },
    libraryListItem: {
      display: 'flex',
      alignItems: 'space-between',
    },
    libraryLine2: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    soundTrackProgess: {
      marginTop: theme.spacing(2),
      marginLeft: '40%',
    },
    libraryProgress: {
      padding: theme.spacing(2),
    },
    '@media (min-width: 1024px)': {
      rootLargeScreen: {
        width: 984,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        margin: theme.spacing(6),
      },
      generalLayout: {
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
        backgroundColor: 'white',
      },
      titleContainer: {
        margin: theme.spacing(2),
        backgroundColor: 'white',
      },
      editContainer: {
        width: '50%',
      },
      graphs: {
        width: '50%',
      },
      sliders: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
  }
})

export default useStyles
