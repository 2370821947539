import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import ToggleOffOutlinedIcon from '@material-ui/icons/ToggleOffOutlined'
import ToggleOnIcon from '@material-ui/icons/ToggleOn'
import {
  Divider,
  Fab,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  Collapse,
  CircularProgress,
} from '@material-ui/core'
import _ from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useSwipeable } from 'react-swipeable'
import { translate as t } from 'react-i18nify'
import Header from '../common/header'
import { handleError } from '../common/handle-error'
import * as model from './sound-track-model'
import * as audiogramsModel from '../audiograms/audiograms-model'
import useStyles from '../styles/sound-tracks-styles'
import { ISoundTracksListProps, ISoundTrack } from '../types/sound-tracks-interfaces'
import { IAudiogram } from '../types/audiograms-interfaces'
import { player } from './sound-track-play'
import Tips from '../help/tips'
import Toast from '../common/toast'
import { logCentral } from '../common/logger'

let trackPlayer: any

const SoundTracksList = (props: ISoundTracksListProps) => {
  const classes = useStyles()
  const [isOneAudiogram, setIsOneAudiogram] = useState(false)
  const [audiograms, setAudiograms] = useState<IAudiogram[]>([])
  const [soundTracks, setSoundTracks] = useState<ISoundTrack[]>([])
  const [trackPlaying, setTrackPlaying] = useState({} as ISoundTrack)
  const [trackSelected, setTrackSelected] = useState({} as ISoundTrack)
  const [anchorEl, setAnchorEl] = useState(null)
  const [showSelectAudiogram, setShowSelectAudiogram] = useState<string[]>([])
  const [showTips, setShowTips] = useState(false)
  const [, setShowQualityFilters] = useState(false)
  const [displayPlayFilteredMeans, setDisplayPlayFilteredMeans] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const confirm = useConfirm()
  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => props.nav && props.nav('audiograms'),
    onSwipedRight: () => props.nav && props.nav('help'),
  })
  const [toast, setToast] = useState('')

  // On page load
  useEffect(() => {
    logEvent('Page load', 'SoundTracks')
  }, [])

  // Clean up the player when unmounting
  useEffect(() => {
    return () => {
      stopPlayer()
    }
    // eslint-disable-next-line
  }, [])

  // Get all sound tracks and audiograms
  useEffect(() => {
    model
      .getAllTracks()
      .then(collected => {
        setSoundTracks(collected)
      })
      .catch(response => {
        handleError('error getting sound tracks', response)
      })

    audiogramsModel
      .getAllAudiograms()
      .then((collected: IAudiogram[]) => {
        setAudiograms(collected)
      })
      .catch(response => {
        handleError('error getting audiograms', response)
      })
  }, [refresh])

  const soundTracksListItems = () => {
    if (soundTracks.length === 0) {
      return <CircularProgress color={'primary'} className={classes.soundTrackProgess} />
    }

    return soundTracks.map((soundTrack: ISoundTrack, index) => {
      if (!isOneAudiogram && soundTrack.audiogramId) setIsOneAudiogram(true)

      const audiogram = _.find(audiograms, { id: soundTrack.audiogramId }) || {}
      return (
        <div key={`${soundTrack.id}-${index}`}>
          <div>
            <ListItem alignItems="flex-start">
              {/* The sound track name */}
              <ListItemText
                primary={soundTrack.name}
                secondary={soundTrack.audiogramId ? audiogram.name : t('soundTracks.noAudiogramSelected')}
              />

              {/* The help icon */}
              <IconButton
                color="secondary"
                className={classes.buttonNarrow}
                aria-label="What does this mean?"
                onClick={event => handleTrackAction(event, 'help', soundTrack.id)}
              >
                {soundTrack.id === trackPlaying.id && trackPlaying.playFiltered ? <Icon>help</Icon> : null}
              </IconButton>

              {/* The toggle the audiogram icon */}
              <div className={classes.toggleContainer}>
                {soundTrack.audiogramId ? (
                  <IconButton
                    color="secondary"
                    className={classes.button}
                    aria-label="Play filtered"
                    onClick={event => handleTrackAction(event, 'playfiltered', soundTrack.id)}
                  >
                    {soundTrack.id === trackPlaying.id && trackPlaying.playFiltered ? (
                      <ToggleOnIcon fontSize={'large'} />
                    ) : (
                      <ToggleOffOutlinedIcon fontSize={'large'} />
                    )}
                  </IconButton>
                ) : null}
                {soundTrack.audiogramId && (
                  <Typography className={classes.toggleText}>
                    {trackPlaying.id === soundTrack.id && trackPlaying.playFiltered
                      ? t('soundTracks.audiogramIsOn')
                      : t('soundTracks.audiogramIsOff')}
                  </Typography>
                )}
              </div>

              {/* The play normal icon */}
              <IconButton
                color="secondary"
                className={classes.button}
                aria-label="Play unfiltered"
                onClick={event => handleTrackAction(event, 'play', soundTrack.id)}
              >
                {soundTrack.id === trackPlaying.id ? <Icon>stop</Icon> : <Icon>play_circle_filled</Icon>}
              </IconButton>

              {/* The 3 dots menu icon */}
              <IconButton
                color="secondary"
                className={classes.buttonNarrow}
                aria-label="Sub-menu"
                onClick={event => handleTrackAction(event, 'submenu', soundTrack.id)}
              >
                <Icon>more_vert</Icon>
              </IconButton>
            </ListItem>
          </div>
          {/* Choose an audiogram dropdown */}
          <div className={classes.selectContainer}>{selectAudiogram(soundTrack.id)}</div>

          <div className={trackPlaying.id === soundTrack.id ? classes.graphs : classes.hidden}>
            {graphs(soundTrack.id)}
          </div>

          <Divider component="li" />
        </div>
      )
    })
  }

  const selectAudiogram = (soundTrackId?: string) => {
    if (!soundTrackId) return null
    return (
      <Collapse in={showSelectAudiogram.includes(soundTrackId)} timeout={700}>
        <Select
          className={classes.selectAudiogram}
          native
          value={''}
          onChange={handleASelectedAudiogram}
          input={<OutlinedInput name="select-audiogram" labelWidth={0} id="select-audiogram" />}
        >
          <option value="" key={'audiogram-none'}>
            {t('soundTracks.selectAnAudiogram')}
          </option>
          {audiograms.map(audiogram => {
            return (
              <option value={audiogram.id} key={audiogram.id}>
                {audiogram.name}
              </option>
            )
          })}
        </Select>
      </Collapse>
    )
  }

  /*
  // Q filters off for now. Until we better understand them. Using the default value = 8
  const qualityFilters = () => {
    return (
      <div className={`${showQualityFilters ? '' : classes.hidden}`}>
        <Typography className={classes.qualitySliderTitle}>Q value of the Biquad filter</Typography>
        <Slider
          className={classes.sliderLoss}
          min={model.qMin}
          max={model.qMax}
          defaultValue={model.qDefault}
          valueLabelDisplay="auto"
          onChange={captureQValue}
        />
      </div>
    )
  }
  /**/

  const oscilloscopeId = 'oscilloscope'
  const oscilloscope = (trackId: string) => <div id={`${oscilloscopeId}-${trackId}`}></div>

  /* Not used for now
  const captureQValue = (event: ChangeEvent<{}>, qValue: number | number[]) => {
    trackPlayer.update(trackPlaying, { Q: qValue })
  } /**/

  const handleASelectedAudiogram = async (event: any) => {
    const selectedId = event.target.value

    // Close the dropdown
    setShowSelectAudiogram([])

    // Update the current track and save
    trackSelected.audiogramId = selectedId
    trackPlaying.audiogramId = selectedId
    setTrackSelected(trackPlaying)
    setTrackPlaying(trackPlaying)
    await model.save(trackSelected)

    // Set for all tracks that don't have an audiogram yet
    let soundtracksCount = 0
    for (const track of soundTracks) {
      if (!track.audiogramId) {
        track.audiogramId = selectedId
        await model.save(track)
        soundtracksCount += 1
      }
    }
    if (soundtracksCount > 1) {
      makeToastMessage(t('soundTracks.tracksUpdated', { n: soundtracksCount }))
    }

    // Force a re-render
    setRefresh(!refresh)
  }

  const toggleTips = () => setShowTips(!showTips)

  const soundTracksList = () => {
    const tips1: string[] = []
    const tips2: string[] = []
    const tips3: string[] = []

    tips1.push('addSoundtrack')
    tips2.push('actionsSoundtrack')
    tips3.push('selectAudiogram')
    tips3.push('createAudiogram')

    return (
      <div className={classes.listContainer}>
        <List className={classes.listRoot}>
          {soundTracksListItems()}

          <div className={classes.fabContainer}>
            <Fab
              id="displayTips"
              color="secondary"
              aria-label="Display tips"
              className={classes.fab}
              onClick={toggleTips}
            >
              <HelpOutlineIcon />
            </Fab>{' '}
            <Fab
              id="addSoundTrack"
              color="primary"
              aria-label="Add Sound Track"
              className={classes.fab}
              onClick={() => props.editTrack('__new__track__')}
            >
              <AddIcon />
            </Fab>
          </div>
        </List>

        {showTips && (
          <>
            <Tips tips={tips1} />
            <Tips tips={tips2} />
            <Tips tips={tips3} />
          </>
        )}
      </div>
    )
  }

  const handleTrackAction = (event: any, what: string, soundTrackId: string | undefined) => {
    setShowSelectAudiogram([])

    if (!soundTrackId) return

    trackPlayer = player(trackPlaying, setTrackPlaying, setShowQualityFilters, soundTracks, audiograms)

    switch (what) {
      case 'playfiltered':
        const newFilterStatus = !trackPlaying.playFiltered
        const _trackPlaying = {
          ...trackPlaying,
          playFiltered: newFilterStatus,
        }
        setTrackPlaying(_trackPlaying)
        trackPlayer.play(soundTrackId, newFilterStatus, `${oscilloscopeId}-${soundTrackId}`)
        logEvent('Play filtered', _trackPlaying.name)
        break

      case 'play':
        if (soundTrackId === trackPlaying.id) {
          trackPlayer.stop()
        } else {
          trackPlayer.play(soundTrackId, false, `${oscilloscopeId}-${soundTrackId}`)
          setDisplayPlayFilteredMeans(false)
          logEvent('Play normal', trackPlaying.name)
        }
        break

      case 'submenu':
        setAnchorEl(event.currentTarget)
        setTrackSelected(_.find(soundTracks, { id: soundTrackId }) as ISoundTrack)
        break

      case 'help':
        setDisplayPlayFilteredMeans(!displayPlayFilteredMeans)
        break

      case 'addaudiogram':
        makeToastMessage(t('soundTracks.firstAddAudiogram'))
        break

      default:
        return null
    }

    // Force a re-render to update the icons
    setRefresh(!refresh)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectAudiogram = () => {
    stopPlayer()
    if (trackSelected.id) setShowSelectAudiogram([trackSelected.id])
    handleClose()
  }

  const handleEdit = () => {
    props.editTrack(trackSelected)
    stopPlayer()
  }

  // Not yet implemented
  // const handleSend = () => {
  //   props.sendTrack(trackSelected)
  //   setAnchorEl(null)
  // }

  const handleDelete = () => {
    confirm({
      title: t('soundTracks.deleteTitle'),
      description: t('soundTracks.deleteDescription', {
        name: trackSelected.name as string,
      }),
    }).then(() => {
      model.remove(trackSelected)
      setRefresh(!refresh)
    })
    setAnchorEl(null)

    stopPlayer()
  }

  const stopPlayer = () => {
    player(trackPlaying, setTrackPlaying, setShowQualityFilters, soundTracks, audiograms).stop()
  }

  const makeToastMessage = (message: string) => {
    setToast(message)
    setTimeout(() => {
      setToast('')
    }, 500)
  }

  const graphs = (trackId?: string) => {
    if (!trackId) return null
    return (
      <>
        {oscilloscope(trackId)}
        {/*Quality filters off for now*/}
        {/*{qualityFilters()}*/}

        <div className={displayPlayFilteredMeans ? '' : classes.hidden}>
          <Tips tips={['playFilteredMeans']} />
        </div>
      </>
    )
  }

  const logEvent = (action: string, label?: string) => {
    logCentral('Sound Tracks', action, label)
  }

  return (
    <div className={classes.root} {...swipeHandlers}>
      <div className={classes.rootLargeScreen}>
        <Header classes={classes} title={t('heading.soundTracks')} menuOrBack={'menu'} nav={props.nav} />

        <div className={classes.generalLayout}>
          <div className={classes.mainArea}>
            {soundTracksList()}

            <Menu
              id="soundtracksSubmenu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleSelectAudiogram}>{t('soundTracks.selectAudiogram')}</MenuItem>
              <MenuItem onClick={handleEdit}>{t('general.edit')}</MenuItem>
              {/* Not yet <MenuItem onClick={handleSend}>{t('general.send')}</MenuItem>*/}
              <MenuItem onClick={handleDelete}>{t('general.delete')}</MenuItem>
            </Menu>
          </div>
        </div>

        <Toast message={toast} horizontal={'left'} />
      </div>
    </div>
  )
}

export default SoundTracksList
