// Third party imports
import React, { useEffect, useState } from 'react'
import ReactGA from 'react-ga'
import { ConfirmProvider } from 'material-ui-confirm'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { FlagsProvider } from 'react-unleash-flags'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { setTranslations, setLocale, translate as t } from 'react-i18nify'

// Our imports
import SoundTracks from './sound-tracks/sound-tracks'
import { saveBeforeInstallPromptEvent, installApp } from './common/install-app'
import Audiograms from './audiograms/audiograms'
import Help from './help/help'
import Admin from './admin/admin'
import Toast from './common/toast'
import * as configModel from './common/config-model'
import * as soundTrackModel from './sound-tracks/sound-track-model'
import translations from './translations/all-languages.js'
import PrivacyPolicy from './help/privacyPolicy'

ReactGA.initialize('UA-174495989-1')
ReactGA.event({
  category: 'App',
  action: 'App load',
})

setTranslations(translations)
setLocale('en') // Default language
configModel.get('language').then(language => {
  if (language) setLocale(language as string)
})

const App: React.FC = () => {
  const defaultScreen = ['soundTracks', 'audiograms', 'help', 'admin'][0]

  const [toast, setToast] = useState('')
  const [navToWhere, setNavToWhere] = useState(defaultScreen)
  const [installable, setInstallable] = useState(false)
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null)

  // console.log('browser is use: ', browserName, deviceDetect())
  // console.log('isBrowser', isBrowser)
  // console.log('isMobile', isMobile)
  // console.log('isSafari', isSafari)
  // console.log('isInstallable', installable)

  // Things on page load
  useEffect(() => {
    configModel.get('initialVersion').then(data => {
      if (!data) {
        // This is the very first load
        window.addEventListener('beforeinstallprompt', event => {
          setInstallable(true)
          saveBeforeInstallPromptEvent(event)
        })

        setNavToWhere('help')

        configModel.set('initialVersion', process.env.REACT_APP_VERSION + '-demo')
        configModel.set('installedVersion', process.env.REACT_APP_VERSION)
      } else {
        // This is not the first load. If no language chosen, send to help and notify
        configModel.get('language').then(language => {
          if (!language) {
            setToast(t('toast.chooseLanguage'))
            setNavToWhere('help')
          } else {
            // Language is sorted, so check if there is an update pending
            const versionPromises = []
            versionPromises.push(configModel.get('installedVersion'))
            versionPromises.push(configModel.get('updateIsAvailable'))

            Promise.all(versionPromises).then(results => {
              const installedVersion = results[0] || ''
              const updateIsAvailable = results[1] || false

              if (updateIsAvailable && installedVersion === process.env.REACT_APP_VERSION) {
                // Current version needs updating, but only remind once
                setToast(t('general.updateReminder'))
                configModel.set('updateIsAvailable', false)
              } else if (updateIsAvailable && installedVersion < (process.env.REACT_APP_VERSION || '')) {
                // Current version has updated, so fix the admin
                configModel.set('updateIsAvailable', false)
                configModel.set('installedVersion', process.env.REACT_APP_VERSION)
              }
            })
          }
        })
      }
    })

    configModel.get('navToWhere').then(data => {
      if (data) {
        setNavToWhere(data as string)
        configModel.set('navToWhere', '')
      }
    })
  }, [])

  // Handle a shared file
  useEffect(() => {
    if (acceptedFile && acceptedFile.name) {
      setToast(`Adding soundtrack ${acceptedFile.name}...`)
      soundTrackModel.addImportedFile(acceptedFile).then(() => {
        navTo('help')
      })
    }
  }, [acceptedFile])

  // Listener to accept an incoming shared file
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener('message', event => {
      // console.log('serviceWorker event message', event)
      setAcceptedFile(event.data.file)
    })
  }

  const navTo = (where: string) => {
    setNavToWhere(where)
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#f98a5e', // orange
      },
      secondary: {
        main: '#6bbcda', // blue
      },
      contrastThreshold: 3,
      tonalOffset: 0.6,
    },
  })

  return (
    <Router>
      <FlagsProvider>
        <ThemeProvider theme={theme}>
          <ConfirmProvider>
            <Switch>
              <Route path="/privacy">
                <PrivacyPolicy nav={navTo} />
              </Route>
              <Route path="/">
                {navToWhere === 'soundTracks' ? <SoundTracks nav={navTo} /> : null}

                {navToWhere === 'audiograms' ? <Audiograms nav={navTo} /> : null}

                {navToWhere === 'help' ? <Help installApp={installable && installApp} nav={navTo} /> : null}

                {navToWhere === 'admin' ? <Admin nav={navTo} /> : null}
              </Route>
            </Switch>

            <Toast message={toast} />
          </ConfirmProvider>
        </ThemeProvider>
      </FlagsProvider>
    </Router>
  )
}

export default App
