// Third party imports
import React, { useState } from 'react'
import { Card, CardContent, Icon, Typography, Fab } from '@material-ui/core'
import shortid from 'shortid'
import { translate as t } from 'react-i18nify'

// Our imports
import { ITipsProps } from '../types/help-interfaces'
import Toast from '../common/toast'
import useStyles from '../styles/help-styles'

const Tips = (props: ITipsProps) => {
  const classes = useStyles()

  const [toast, setToast] = useState('')
  const infoIcon = <Icon className={`${classes.iconInText} ${classes.paddingRight}`}>info_outline</Icon>
  const menuIcon = (
    <span className={classes.iconBorder}>
      <Icon className={classes.iconInText}>menu</Icon>
    </span>
  )
  const vertMenuIcon = (
    <Icon className={classes.iconInText} color={'secondary'}>
      more_vert
    </Icon>
  )
  const playNormal = (
    <Icon className={classes.iconInText} color={'secondary'}>
      play_circle_filled
    </Icon>
  )
  const toggleIcon = (
    <Icon className={classes.iconInText} color={'secondary'}>
      toggle_off_outline
    </Icon>
  )

  const highlightAdd = () => {
    setToast(t('toast.usePlus'))
    setTimeout(() => {
      setToast('')
    }, 500)
  }

  const highlightMenu = () => {
    setToast(t('toast.useMenu'))
    setTimeout(() => {
      setToast('')
    }, 500)
  }

  const addIcon = (
    <Fab color="primary" className={`${classes.iconInText} ${classes.iconSmall}`} size={'small'} onClick={highlightAdd}>
      <Icon>add</Icon>
    </Fab>
  )

  const tips: JSX.Element[] = []
  props.tips.forEach(tip => {
    if (tip === 'selectAudiogram')
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.use')} {vertMenuIcon} {t('tips.toSelectAudiogram')}
        </Typography>,
      )
    if (tip === 'createAudiogram')
      tips.push(
        <Typography variant="body1" component="p" onClick={highlightMenu}>
          {t('tips.createAudiogramUsing')} {menuIcon} {t('tips.buttonOrSwipe')}
        </Typography>,
      )
    if (tip === 'addSoundtrack')
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.use')} {addIcon} {t('tips.addSoundtrack')}
        </Typography>,
      )
    if (tip === 'actionsSoundtrack') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.use')} {playNormal} {t('tips.playNormal')}
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.use')} {toggleIcon} {t('tips.playWith')}
        </Typography>,
      )
    }
    if (tip === 'sendAudiogramToMe')
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.sendAudiogramToMe')}
        </Typography>,
      )
    if (tip === 'addAudiogram')
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.use')} {addIcon} {t('tips.toAddAudiogram')}
        </Typography>,
      )
    if (tip === 'actionsAudiogram')
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.use')} {vertMenuIcon} {t('tips.moreOptions')}
        </Typography>,
      )
    if (tip === 'addPointAudiogram')
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.clickToChange')}
        </Typography>,
      )
    if (tip === 'addPointsAudiogram') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.addPointsDescription')}
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.addPointsInstruction')}
        </Typography>,
      )
    }
    if (tip === 'receiveAudiogram') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.senderGivesDetails')}
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.clickReceive')}
        </Typography>,
      )
    }
    if (tip === 'sendAudiogramDirectly') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.chooseIdentity')}
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.giveDetails')}
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.availablePeriod')}
        </Typography>,
      )
    }
    if (tip === 'exportAudiogram') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.exportAudiogram')}
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.sendCode')}
        </Typography>,
      )
    }
    if (tip === 'copiedClipboard') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.copiedClipboard')}
        </Typography>,
      )
    }
    if (tip === 'playFilteredMeans') {
      tips.push(
        <Typography variant="body1" component="p">
          {t('tips.playFilteredMeans')}
        </Typography>,
      )
    }
    if (tip === 'whatsnewTips') {
      tips.push(
        <Typography variant="body1" component="p">
          See above the current messages with timestamp they were made
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          The user will see only messages they haven't already seen
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          Use def:1 (or any number) to delete the first messages
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          Use del:1 (or any number) to delete the last messages
        </Typography>,
      )
      tips.push(
        <Typography variant="body1" component="p">
          Then reload. You or the user may experience cached values, so be patient
        </Typography>,
      )
    }
  })

  const renderTips = () => {
    return tips.map(tip => {
      return (
        <div key={shortid.generate()} className={classes.oneTip}>
          {infoIcon}
          {tip}
        </div>
      )
    })
  }

  return tips.length <= 0 ? null : (
    <Card className={classes.oneCard}>
      <CardContent className={classes.tipContent}>{renderTips()}</CardContent>
      <Toast message={toast} horizontal={'left'} />
    </Card>
  )
}

export default Tips
