let deferredInstallPrompt: any = null;

window.addEventListener('beforeinstallprompt', saveBeforeInstallPromptEvent);

export function saveBeforeInstallPromptEvent(event: any) {
    deferredInstallPrompt = event;
}

export function installApp(event: any) {
    if (!deferredInstallPrompt) {
        console.log('installApp could not find the defferedinstallprompt')
        return
    }

    deferredInstallPrompt.prompt();
    deferredInstallPrompt.userChoice
        .then((choice: any) => {
            deferredInstallPrompt = null;
            if (choice.outcome === 'accepted') {
                return true
            } else {
                return false
            }
        });
}

// // Add event listener for appinstalled event
// window.addEventListener('appinstalled', logAppInstalled);
// function logAppInstalled(event) {
//     console.log('App was installed.', event);
// }
