// Third party imports
import React, { ChangeEvent, useEffect, useState } from 'react'
import {
  Typography,
  Button,
  TextareaAutosize,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fab,
  Checkbox,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useConfirm } from 'material-ui-confirm'
import { useSwipeable } from 'react-swipeable'
import { translate as t } from 'react-i18nify'

// Our imports
import { IAdminProps } from './admin-interfaces'
import Header from '../common/header'
import Toast from '../common/toast'
import useStyles from '../styles/admin-styles'
import notificationsService, { languageTexts } from '../common/notifications-service'
import { ILibraryTrack } from '../types/sound-tracks-interfaces'
import libraryService from '../common/library-service'
import { handleError } from '../common/handle-error'
import AddIcon from '@material-ui/icons/Add'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tips from '../help/tips'

const MAX_FILE_SIZE = 10
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 1024 * 1024

const Admin = (props: IAdminProps) => {
  const [toast, setToast] = useState('')
  const [whatsnewMsg, setWhatsnewMsg] = useState<languageTexts>({ en: '', nl: '' })
  const [expanded, setExpanded] = useState('')
  const [file, setFile] = useState()
  const [whatsnewTextEn, setWhatsnewTextEn] = useState<string[]>([])
  const [whatsnewTextNl, setWhatsnewTextNl] = useState<string[]>([])
  const [libraryTracks, setLibraryTracks] = useState<ILibraryTrack[]>([])
  const [displayAddFile, setDisplayAddFile] = useState(false)
  const [fileName, setFileName] = useState('')
  const [fileType, setFileType] = useState('')
  const [fileTitle, setFileTitle] = useState('')
  const [artist, setArtist] = useState('')
  const [genres, setGenres] = useState('')
  const [hasSinging, setHasSinging] = useState(false)
  const [isSoundEffect, setIsSoundEffect] = useState(false)
  const [credits, setCredits] = useState('')
  const [startUpload, setStartUpload] = useState(false)
  const [displayProgress, setDisplayProgress] = useState(false)
  const [addMessage, setAddMessage] = useState('')

  const classes = useStyles()
  const confirm = useConfirm()
  const handlers = useSwipeable({
    onSwipedLeft: () => props.nav && props.nav('soundTracks'),
    onSwipedRight: () => props.nav && props.nav('audiograms'),
  })

  // Op page load
  useEffect(() => {
    // Get whatsnew messages
    const languages = ['en', 'nl']
    for (const language of languages) {
      notificationsService.retrieveWhatsNew(language).then(texts => {
        if (texts.length) {
          if (language === 'en') setWhatsnewTextEn(texts)
          if (language === 'nl') setWhatsnewTextNl(texts)
        }
      })
    }

    // Get library contents
    setAddMessage('')
    libraryService
      .getContents()
      .then(libraryContents => {
        setLibraryTracks(libraryContents)
      })
      .catch(error => {
        handleError('Error while getting library contents', error)
      })
  }, [])

  // Effect to upload a file
  useEffect(() => {
    // Upload to library, get back updated library contents list
    if (startUpload && file) {
      setAddMessage('')
      setStartUpload(false)
      setDisplayProgress(true)
      const fileMeta: ILibraryTrack = {
        title: fileTitle,
        artist,
        genres: genres.split(','),
        hasSinging,
        isSoundEffect,
        fileName,
        fileType,
        credits,
      }

      libraryService
        .addTrack(file, fileMeta)
        .then((libraryContents: ILibraryTrack[]) => {
          setLibraryTracks(libraryContents)
          setFile('')
          setExpanded('')
          setDisplayAddFile(false)
        })
        .catch(error => {
          handleError('Error while uploading library track', error)
        })
        .finally(() => {
          cancelUpload()
        })
    }
    // disable the 5000 dependencies like file, expanded, etc
    // eslint-disable-next-line
  }, [startUpload])

  // Effect to check on already existing
  useEffect(() => {
    if (fileTitle && libraryTracks) {
      const thisTrack = (fileTitle + artist).replace(/\s/g, '').toLowerCase()
      const haveTrack = libraryTracks.find(track => {
        const titleArtist = (track.title + track.artist).replace(/\s/g, '').toLowerCase()
        return titleArtist === thisTrack
      })
      if (haveTrack) setAddMessage(`Note: The existing track (for: ${fileTitle} ${artist}) will be overwritten`)
    }
  }, [fileTitle, artist, libraryTracks])

  const cancelUpload = () => {
    setFile('')
    setExpanded('')
    setDisplayAddFile(false)
    setDisplayProgress(false)
    setStartUpload(false)
    setFileName('')
    setFileType('')
    setFileTitle('')
    setArtist('')
    setGenres('')
    setAddMessage('')
  }

  const handleSource = (source: string) => (event: any, isExpanded: boolean) => {
    setToast('')
    setExpanded(isExpanded ? source : '')
  }

  const handleFindFile = async (event: any) => {
    const usersFile = event.target.files[0]

    if (!usersFile) {
      setToast(t('soundTracks.somethingWrong'))
      return false
    }

    if (usersFile.type.slice(0, 5) !== 'audio') {
      setToast(t('soundTracks.notAudioFile'))
    }

    if (usersFile.size > MAX_FILE_SIZE_BYTES) {
      setToast(t('admin.maxSize', { n: MAX_FILE_SIZE }))
    }

    setFile(usersFile)

    const _fileArray = usersFile.name.split('.')
    const _fileType = _fileArray.pop()
    const _fileName = _fileArray.join('.')
    if (!fileTitle) setFileTitle(_fileName)
    if (!fileName) setFileName(_fileName)
    if (!fileType) setFileType('.' + _fileType)
  }

  // Do we want this?
  const playTrack = (track: ILibraryTrack) => {
    setToast('Not yet... :-(')
  }

  const deleteTrack = async (track: ILibraryTrack) => {
    setToast('')

    confirm({
      title: t('admin.deleteTrack'),
      description: `${t('admin.reallyDeleteTrack')} ${track.title}`,
    }).then(async () => {
      const libraryContents = await libraryService.deleteTrack(track)
      setLibraryTracks(libraryContents)
    })
  }

  const addTrack = async () => {
    setDisplayAddFile(true)
  }

  const handleSetWhatsnewMsg = (event: ChangeEvent<HTMLTextAreaElement>, language: 'nl' | 'en') => {
    setToast('')
    if (event) {
      if (language === 'nl') {
        setWhatsnewMsg({ ...whatsnewMsg, nl: event.target.value })
      }
      if (language === 'en') {
        setWhatsnewMsg({ ...whatsnewMsg, en: event.target.value })
      }
    }
  }

  const handleSetWhatsnewMsgOk = async () => {
    setToast('')
    // Update the WhatsNew file
    const result = await notificationsService.whatsnewSend(whatsnewMsg)
    alert(`Result: ${result}`)
  }

  return (
    <div className={classes.root} {...handlers}>
      <Header classes={classes} title={t('admin.admin')} menuOrBack={'menu'} nav={props.nav} />

      <div className={classes.adminContainer}>
        <ExpansionPanel
          className={classes.panelRoot}
          expanded={expanded === 'library'}
          onChange={handleSource('library')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('admin.manageLibrary')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {/* List of library items */}
            <div className={displayAddFile ? classes.hidden : ''}>
              <List className={classes.listRoot}>
                {libraryTracks.length === 0 ? <p>{t('admin.libraryIsEmpty')}</p> : null}
                {libraryTracks.map(track => {
                  return (
                    <div key={track.fileName + track.fileType}>
                      {!track.fileName ? null : (
                        <ListItem className={classes.libraryListItem}>
                          <ListItemText primary={`${track.title}, ${track.artist}`} />
                          <PlayArrowIcon color={'secondary'} onClick={() => playTrack(track)} />
                          <DeleteIcon color={'secondary'} onClick={() => deleteTrack(track)} />
                        </ListItem>
                      )}
                      <Divider component="li" />
                    </div>
                  )
                })}

                <Fab
                  id="addSoundTrack"
                  color="primary"
                  aria-label="Add Sound Track"
                  className={classes.fab}
                  onClick={addTrack}
                >
                  <AddIcon />
                </Fab>
              </List>
            </div>

            {/* Form to add a file */}
            <div className={displayAddFile ? '' : classes.hidden}>
              <p>
                <b>MP3 files are preferred !</b>
              </p>
              <TextField
                id="soundTrackFile"
                label="File"
                placeholder="File"
                className={classes.textField}
                margin="normal"
                type="file"
                onChange={handleFindFile}
              />
              {/* The file name */}
              <TextField
                id="soundTrackFileName"
                label={t('admin.fileName')}
                placeholder={'File name without type like .mp3'}
                className={classes.textField}
                margin="normal"
                onChange={event => setFileName(event.target.value)}
                value={fileName}
              />
              {/* The file type - not editable */}
              <TextField
                id="soundTrackFileType"
                label={t('admin.fileType')}
                placeholder={t('admin.fileType')}
                className={classes.textField}
                margin="normal"
                onChange={event => setFileType(event.target.value)}
                value={fileType}
                disabled={true}
              />
              {/* The title */}
              <TextField
                id="soundTrackTitle"
                label={t('admin.titleDisplayed')}
                placeholder={'Title as displayed'}
                className={classes.textField}
                margin="normal"
                onChange={event => setFileTitle(event.target.value)}
                value={fileTitle}
              />
              {/* The artist */}
              <TextField
                id="soundTrackArtist"
                label={t('admin.artist')}
                placeholder={t('admin.artist')}
                className={classes.textField}
                margin="normal"
                onChange={event => setArtist(event.target.value)}
                value={artist}
              />
              {/* The genres */}
              <TextField
                id="soundTrackGenres"
                label={t('admin.genres')}
                placeholder={t('admin.genres')}
                className={classes.textField}
                margin="normal"
                onChange={event => setGenres(event.target.value)}
                value={genres}
              />
              {/* The credits */}
              <TextField
                id="soundTrackCredits"
                label={t('admin.credits')}
                placeholder={t('admin.credits')}
                className={classes.textField}
                margin="normal"
                onChange={event => setCredits(event.target.value)}
                value={credits}
              />
              {/* Has Singing */}
              <Checkbox checked={hasSinging} onChange={() => setHasSinging(!hasSinging)} /> {t('admin.hasSinging')}
              <br />
              {/* is Sound Effect */}
              <Checkbox checked={isSoundEffect} onChange={() => setIsSoundEffect(!isSoundEffect)} />{' '}
              {t('admin.isSoundEffect')}
              <Divider />
              <Button variant={'outlined'} color={'secondary'} onClick={() => cancelUpload()}>
                Cancel
              </Button>
              &nbsp;
              <Button variant={'contained'} color={'secondary'} onClick={() => setStartUpload(true)}>
                Ok
              </Button>
              &nbsp;
              {displayProgress && <CircularProgress color="primary" size={20} />}
              <p className={classes.addMessage}>{addMessage}</p>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <ExpansionPanel
          className={classes.panelRoot}
          expanded={expanded === 'whatsnew'}
          onChange={handleSource('whatsnew')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{t('admin.whatsnewMsg')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            {/* English */}
            <div className={classes.content}>
              <List>
                {whatsnewTextEn.map((t, index) => (
                  <ListItem key={'whatssnewE' + index}>
                    <Typography className={classes.whatsnewText}>{t}</Typography>
                  </ListItem>
                ))}
              </List>
              <TextareaAutosize
                placeholder={'English text [or def:1 or del:1]'}
                rowsMin={4}
                className={classes.whatsnewText}
                onChange={event => handleSetWhatsnewMsg(event, 'en')}
              />

              {/* Nederlands */}
              <TextareaAutosize
                placeholder={'Nederlandse tekst [of def:1 of del:1]'}
                rowsMin={4}
                className={classes.whatsnewText}
                onChange={event => handleSetWhatsnewMsg(event, 'nl')}
              />
              <Button onClick={handleSetWhatsnewMsgOk}>OK</Button>
              <List>
                {whatsnewTextNl.map((t, index) => (
                  <ListItem key={'whatsnewN' + index}>
                    <Typography className={classes.whatsnewText}>{t}</Typography>
                  </ListItem>
                ))}
              </List>

              <Tips tips={['whatsnewTips']} />
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Toast message={toast} horizontal={'left'} />
      </div>
    </div>
  )
}

export default Admin
