// Third party imports
import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import { Divider, Fab, Icon, IconButton, List, ListItem, ListItemText, Menu, MenuItem } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import _ from 'lodash'
import { useConfirm } from 'material-ui-confirm'
import { useSwipeable } from 'react-swipeable'
import { translate as t } from 'react-i18nify'

// Our imports
import Header from '../common/header'
import { handleError } from '../common/handle-error'
import * as model from './audiograms-model'
import useStyles from '../styles/sound-tracks-styles'
import { IAudiogram, IAudiogramsListProps } from '../types/audiograms-interfaces'
import Tips from '../help/tips'
import Toast from '../common/toast'
import { logCentral } from '../common/logger'

const AudiogramsList = (props: IAudiogramsListProps) => {
  const classes = useStyles()
  const [audiograms, setAudiograms] = useState([])
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentAudiogram, setCurrentAudiogram] = useState({} as IAudiogram)
  const [refresh, setRefresh] = useState(false)
  const [showTips, setShowTips] = useState(false)
  const [toast, setToast] = useState('')
  const confirm = useConfirm()
  const handlers = useSwipeable({
    onSwipedLeft: () => props.nav && props.nav('help'),
    onSwipedRight: () => props.nav && props.nav('soundTracks'),
  })

  // On page load
  useEffect(() => {
    logEvent('Page load', 'Audiograms')
  }, [])

  // Get all audiograms
  useEffect(() => {
    model
      .getAllAudiograms()
      .then(collected => {
        if (collected) {
          setAudiograms(collected as never[])
        }
      })
      .catch(response => {
        handleError('error getting audiograms', response)
      })
  }, [refresh])

  const audiogramsListItems = () => {
    return audiograms.map((audiogram: IAudiogram) => {
      return (
        <div key={audiogram.id}>
          <ListItem alignItems="flex-start">
            <ListItemText primary={audiogram.name} />

            <IconButton
              color="secondary"
              className={classes.button}
              aria-label="Sub-menu"
              onClick={event => handleAction(event, 'submenu', audiogram.id)}
            >
              <Icon>more_vert</Icon>
            </IconButton>
          </ListItem>
          <Divider component="li" />
        </div>
      )
    })
  }

  const toggleTips = () => setShowTips(!showTips)

  const audiogramsList = () => {
    const tips: string[] = []
    if (audiograms.length < 4) tips.push('addAudiogram')
    if (audiograms && audiograms.length < 4) {
      tips.push('sendAudiogramToMe')
      tips.push('actionsAudiogram')
    }

    logEvent('List of audiograms')

    return (
      <div className={classes.listContainer}>
        <List className={classes.listRoot}>{audiogramsListItems()}</List>

        <div className={classes.fabContainer}>
          <Fab
            id="displayTips"
            color="secondary"
            aria-label="Display tips"
            className={classes.fab}
            onClick={toggleTips}
          >
            <HelpOutlineIcon />
          </Fab>{' '}
          <Fab
            id="addAudiogram"
            color="primary"
            aria-label="Add Audiogram"
            className={classes.fab}
            onClick={() => props.editAudiogram('__new__audiogram__')}
          >
            <AddIcon />
          </Fab>
        </div>

        {showTips && <Tips tips={tips} />}
      </div>
    )
  }

  const handleAction = (event: any, what: string, audiogramId: string | undefined) => {
    setToast('')

    if (!audiogramId) return

    switch (what) {
      case 'submenu':
        setAnchorEl(event.currentTarget)
        setCurrentAudiogram(_.find(audiograms, { id: audiogramId }) as IAudiogram)
        break

      default:
        return null
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEdit = () => {
    props.editAudiogram(currentAudiogram)
    setAnchorEl(null)
  }

  const handleExport = () => {
    props.exportAudiogram(currentAudiogram)
    setAnchorEl(null)
  }

  const handleDelete = () => {
    confirm({
      title: t('audiograms.deleteTitle'),
      description: t('audiograms.deleteDescription', { name: currentAudiogram.name as string }),
    }).then(() => {
      logEvent('Delete audiogram', currentAudiogram.name)
      model.remove(currentAudiogram)
      setRefresh(!refresh)
    })
    setAnchorEl(null)
  }

  const handleReset = () => {
    confirm({
      title: t('audiograms.resetTitle'),
      description: t('audiograms.resetDescription', { name: currentAudiogram.name as string }),
    }).then(() => {
      model.resetExample(currentAudiogram).then(() => {
        setRefresh(!refresh)
        setToast(t('audiograms.audiogramIsReset'))
      })
    })
    setAnchorEl(null)
  }

  const logEvent = (action: string, label?: string) => {
    logCentral('Audiograms', action, label)
  }

  return (
    <div className={classes.root} {...handlers}>
      <div className={classes.rootLargeScreen}>
        <Header classes={classes} title={t('heading.audiograms')} menuOrBack={'menu'} nav={props.nav} />

        {audiogramsList()}

        <Menu id="audiogramSubmenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={handleEdit}>{t('general.edit')}</MenuItem>
          <MenuItem onClick={handleExport}>{t('general.send')}</MenuItem>
          <MenuItem onClick={handleDelete}>{t('general.delete')}</MenuItem>
          {currentAudiogram && !Boolean(currentAudiogram.exampleId) ? null : (
            <MenuItem onClick={handleReset}>{t('general.reset')}</MenuItem>
          )}
        </Menu>

        <Toast message={toast} />
      </div>
    </div>
  )
}

export default AudiogramsList
