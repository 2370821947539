import axios from 'axios'
import cryptoJs from 'crypto-js'
import ReactS3Client from 'react-aws-s3-typescript'

import constants from './constants'
import * as configModel from '../common/config-model'

export interface languageTexts {
  [language: string]: string
}

const generateS3UrlParts = (
  userId: string,
  password: string,
  folderOrFull: 'folder' | 'full' | 'whatsnewFolder' | 'whatsnew',
): string => {
  const s3Root = `https://${constants.S3_BUCKET_NAME}.s3-${constants.S3_REGION}.amazonaws.com/`

  // Hash the userId and relevant folder
  const hashedUserId = cryptoJs.HmacSHA256(userId, password)
  const folderPath = `${process.env.REACT_APP_ENVIRONMENT}/${constants.NOTIFICATIONS_FOLDER}/${hashedUserId}`
  const whatsnewPath = `${process.env.REACT_APP_ENVIRONMENT}/${constants.WHATSNEW_FOLDER}`

  switch (folderOrFull) {
    case 'folder':
      return folderPath

    case 'full':
      return `${s3Root}${folderPath}/${constants.NOTIFICATIONS_FILE_NAME}.${constants.NOTIFICATIONS_FILE_TYPE}`

    case 'whatsnewFolder':
      return whatsnewPath

    case 'whatsnew':
      // userId is misused here to carry the language
      return `${s3Root}${whatsnewPath}/${constants.NOTIFICATIONS_FILE_NAME}-${userId}.${constants.NOTIFICATIONS_FILE_TYPE}`
  }

  return ''
}

// Reader to actually read the file
const streamFile = async (_path: string) => {
  try {
    const data = await axios.get(_path, { headers: { 'Cache-Control': 'no-cache' } })
    return data.data
  } catch (error) {
    // There are no notifications
    return ''
  }
}

const notificationsService = {
  retrieve: async (userId: string, password: string): Promise<string[]> => {
    let notifications: string[] = []
    // Build the fileName on s3 and retrieve the file for user notifications
    const fullPath = generateS3UrlParts(userId, password, 'full')
    const notificationsEncoded = await streamFile(fullPath)
    notifications = notificationsService.decode(notificationsEncoded)

    // Now look if we have "whats new" notifications
    const hasSeenUpto = (await configModel.get('hasSeenWhatsNew')) || ''
    const language = (await configModel.get('language')) as string
    const whatsNewMessages = await notificationsService.retrieveWhatsNew(language)
    let relevantMsgs: string[] = []
    if (whatsNewMessages) {
      for (const m of whatsNewMessages) {
        const messageParts = m.split(constants.STRING_SEPARATOR)
        const msgTimestamp = messageParts.shift() || ''
        const msg = messageParts.join(constants.STRING_SEPARATOR)
        if (msgTimestamp > hasSeenUpto) relevantMsgs.push(msg)
      }

      notifications = notifications.concat(relevantMsgs || [])
    }

    return notifications ? Promise.resolve(notifications) : Promise.reject('Error retrieving notifications')
  },

  retrieveWhatsNew: async (language: string) => {
    const versionNotificationsPath = generateS3UrlParts(language, '', 'whatsnew')
    const versionWhatsNewEncoded = await streamFile(versionNotificationsPath)
    return notificationsService.decode(versionWhatsNewEncoded)
  },

  send: async (userId: string, password: string, message: string): Promise<string> => {
    // Are there existing notifications?
    let messagesArray: string[] = []
    try {
      messagesArray = await notificationsService.retrieve(userId, password)
    } catch (error) {
      // Do nothing - there prob were no existing notifications
    }
    if (!messagesArray.includes(message)) {
      messagesArray.push(message)
    }

    // Create a file and upload to S3
    const fileContent = notificationsService.encode(messagesArray)
    const folderPath = generateS3UrlParts(userId, password, 'folder')
    const fileName = constants.NOTIFICATIONS_FILE_NAME
    const s3Config = {
      bucketName: constants.S3_BUCKET_NAME,
      region: constants.S3_REGION,
      accessKeyId: constants.S3_ACCESS_KEY,
      secretAccessKey: constants.S3_SECRET,
    }

    try {
      const s3 = new ReactS3Client({
        ...s3Config,
        dirName: folderPath,
      })
      const fileTypeMime = `text/${constants.NOTIFICATIONS_FILE_TYPE}`
      const file = new File([fileContent], fileName, { type: fileTypeMime })
      await s3.uploadFile(file, fileName)
      return Promise.resolve('')
    } catch (error) {
      return Promise.reject(error.toString())
    }
  },

  whatsnewSend: async (message: languageTexts): Promise<string> => {
    // Create a file and upload to S3
    let result = 'nothing to do'
    const languages = ['en', 'nl']
    for (const language of languages) {
      const folderPath = generateS3UrlParts('anything', '', 'whatsnewFolder')
      const fileName = `${constants.NOTIFICATIONS_FILE_NAME}-${language}`
      const s3Config = {
        bucketName: constants.S3_BUCKET_NAME,
        region: constants.S3_REGION,
        accessKeyId: constants.S3_ACCESS_KEY,
        secretAccessKey: constants.S3_SECRET,
      }

      if (!message[language]) continue

      // Find previous messages
      const existingMessages = await notificationsService.retrieveWhatsNew(language)

      try {
        if (
          message[language].slice(0, 4).toLowerCase() === 'def:' ||
          message[language].slice(0, 4).toLowerCase() === 'del:'
        ) {
          const nrToRemove = parseInt(message[language].split(':')[1])
          if (isNaN(nrToRemove)) continue

          // Remove the nr of message
          const nrMessages = existingMessages.length
          let remainingMessages
          if (message[language].slice(0, 3).toLowerCase() === 'def') {
            remainingMessages = existingMessages.slice(nrToRemove)
          } else {
            remainingMessages = existingMessages.slice(0, nrMessages - nrToRemove)
          }
          if (remainingMessages.length) {
            // Overwrite the file
            const s3 = new ReactS3Client({
              ...s3Config,
              dirName: folderPath,
            })
            const fileContent = notificationsService.encode(remainingMessages)
            const fileTypeMime = `text/${constants.NOTIFICATIONS_FILE_TYPE}`
            const file = new File([fileContent], fileName, { type: fileTypeMime })
            await s3.uploadFile(file, fileName)
            result = `Ok (${remainingMessages.length} remaining}`
          } else {
            const s3 = new ReactS3Client({
              ...s3Config,
            })
            await s3.deleteFile(`${folderPath}/${fileName}.${constants.NOTIFICATIONS_FILE_TYPE}`)
            result = 'Ok (all removed)'
          }
        } else {
          // Append to existng messages
          const timestamp = new Date().toISOString()
          const newMessage = `${timestamp}${constants.STRING_SEPARATOR}${message[language]}`
          const fileContent = notificationsService.encode([...existingMessages, newMessage])

          // Upload + overwrite the file
          const s3 = new ReactS3Client({
            ...s3Config,
            dirName: folderPath,
          })
          const fileTypeMime = `text/${constants.NOTIFICATIONS_FILE_TYPE}`
          const file = new File([fileContent], fileName, { type: fileTypeMime })
          await s3.uploadFile(file, fileName)
          result = 'Ok'
        }
      } catch (error) {
        result = error.toString()
      }
    }
    return Promise.resolve(result)
  },

  delete: async (userId: string, password: string): Promise<string> => {
    const folderPath = generateS3UrlParts(userId, password, 'folder')
    const fileName = `${constants.NOTIFICATIONS_FILE_NAME}.${constants.NOTIFICATIONS_FILE_TYPE}`
    const filePath = `${folderPath}/${fileName}`
    const s3Config = {
      bucketName: constants.S3_BUCKET_NAME,
      region: constants.S3_REGION,
      accessKeyId: constants.S3_ACCESS_KEY,
      secretAccessKey: constants.S3_SECRET,
    }

    try {
      const s3 = new ReactS3Client({
        ...s3Config,
      })
      await s3.deleteFile(filePath)
      return Promise.resolve('')
    } catch (error) {
      return Promise.reject(error.toString())
    }
  },

  encode: (messages: string[]): string => {
    return window.btoa(JSON.stringify(messages))
  },

  decode: (encodedNotifications: string): string[] => {
    if (!encodedNotifications) return []
    try {
      return JSON.parse(window.atob(encodedNotifications))
    } catch (error) {
      return []
    }
  },
}

export default notificationsService
