import localforage from 'localforage'
import shortid from 'shortid'
import { localforageConfig } from '../common/localforage-config'
import { handleError } from '../common/handle-error'
import { JingleBells } from './example-jingle-bells'
import { BabyLaugh } from './example-baby-laugh'
import { PingPing } from './example-ping-ping'
import { ForestBird } from './example-forest-bird'
import { translate as t } from 'react-i18nify'

localforageConfig()
const STORE_PREFIX = 'soundtrack.'

declare interface ISoundTrack {
  id?: string
  name?: string
  file?: File
  audiogramId?: string
  exampleId?: string
}

declare interface IExampleUrls {
  [name: string]: string
}

// Frequency constants used for the sound tracks
const qMin = 0
const qMax = 100
const qDefault = 8 // // This Q value is chosen based on "with a flat 70db ag, you can barely hear the sound"
const detuneDefault = 0

const save = async (soundTrack: ISoundTrack) => {
  if (!soundTrack.id) {
    const id = STORE_PREFIX + shortid.generate()

    // Convert the file (blob) to a base64 string
    try {
      await fileToBase64(soundTrack)

      return await localforage
        .setItem(id, soundTrack)
        .then(response => {
          return true
        })
        .catch(error => {
          handleError('Saving', error)
        })
    } catch (error) {
      handleError('Error: Cannot file, decode or save this file', error)
    }
  }

  // This is an update, so copy the track, remove and re-save with same id
  if (soundTrack.id) {
    const originalId = soundTrack.id
    const originalFile = soundTrack.file
    await localforage.removeItem(originalId)
    delete soundTrack.id

    soundTrack.file = originalFile
    await fileToBase64(soundTrack)

    return await localforage
      .setItem(originalId, soundTrack)
      .then(() => true)
      .catch(error => {
        handleError('Saving', error)
      })
  }
}

const remove = async (soundTrack: ISoundTrack) => {
  if (!soundTrack || !soundTrack.id) return

  return await localforage
    .removeItem(soundTrack.id)
    .then(() => true)
    .catch(error => error)
}

const getAllTracks = async () => {
  let collected: ISoundTrack[] = []

  return localforage
    .iterate((soundTrackEncoded: any, id) => {
      let collector: ISoundTrack = {}
      if (id.slice(0, STORE_PREFIX.length) === STORE_PREFIX) {
        collector.id = id
        collector.name = soundTrackEncoded.name
        collector.audiogramId = soundTrackEncoded.audiogramId
        collector.exampleId = soundTrackEncoded.exampleId

        base64ToFile(soundTrackEncoded)
        collector.file = soundTrackEncoded.file
        collected.push(collector)
      }
    })
    .then(async () => {
      if (collected.length <= 0) {
        await addExample()
      }

      // Sort all tracks by name
      collected.sort((a, b) => {
        if (!a || !b || !a.name || !b.name) return 0
        return a.name.localeCompare(b.name)
      })

      return collected
    })
}

const addExample = async () => {
  // The data URL of an example sound track
  const exampleDataUrls = getExampleTracks()

  for (const example of Object.keys(exampleDataUrls)) {
    // Get the components
    const byteString = atob(exampleDataUrls[example].split(',')[1])
    const mimeString = exampleDataUrls[example].split(',')[0].split(':')[1].split(';')[0]

    // Write to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    const exampleBlob = new Blob([ab], { type: mimeString })
    const exampleFile = new File([exampleBlob], 'example soundtrack', { type: mimeString })
    const exSoundTrack: ISoundTrack = {
      file: exampleFile,
      name: `${t('soundTracks.example')} - ${t('soundTracks.example' + example)}`,
      exampleId: example,
    }
    await save(exSoundTrack)
  }
}

// Replace all examples - required when language changes
const replaceExamples = async () => {
  // Delete current examples
  const tracks = await getAllTracks()
  const examples = tracks.filter(track => Boolean(track.exampleId))
  for (const example of examples) {
    remove(example)
  }

  await addExample()
  return Promise.resolve()
}

const addImportedFile = async (file: File) => {
  return await save({
    file,
    name: file.name,
  })
}

export { qMin, qMax, qDefault, detuneDefault, save, remove, getAllTracks, addExample, replaceExamples, addImportedFile }

const fileToBase64 = async (soundTrack: ISoundTrack) => {
  return new Promise((resolve, reject) => {
    if (!soundTrack.file) reject(t('audiograms.noSoundTrack'))

    const fileReader = new FileReader()
    fileReader.onload = async function (event) {
      // @ts-ignore
      const base64ToSave = event.target.result || ''
      soundTrack.file = base64ToSave
      resolve()
    }
    // @ts-ignore
    fileReader.readAsDataURL(soundTrack.file)
  })
}

const base64ToFile = async (soundTrackEncoded: any) => {
  if (soundTrackEncoded.file && typeof soundTrackEncoded.file === 'string') {
    const encodedParts = soundTrackEncoded.file.split(',')
    const mimeType = encodedParts[0].match(/:(.*?);/)[1]
    const binaryString = atob(encodedParts[1])
    let n = binaryString.length
    let u8array = new Uint8Array(n)
    while (n--) {
      u8array[n] = binaryString.charCodeAt(n)
    }

    soundTrackEncoded.file = new File([u8array], soundTrackEncoded.name, { type: mimeType })
  } else {
    soundTrackEncoded.name += ' = MISSING. PLEASE CONTACT SUPPORT (SEE HELP)'
  }
}

const getExampleTracks = (): IExampleUrls => {
  return {
    BabyLaugh,
    PingPing,
    JingleBells,
    ForestBird,
  }
}
