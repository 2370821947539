let mediaRecorder: any
let audioContext: any
// Get an audiContext
try {
    // @ts-ignore
    audioContext = new window.AudioContext()
} catch (response) {/*ignore this error and try again*/}
if (!audioContext) {
    try {
        // @ts-ignore
        audioContext = new window.webkitAudioContext()
    } catch (response) {
        throw new Error("In this browser: no audioContext available")
    }
}

export const recorder = () => {

    const record = async (maxTimeSec: number = 30) => {
        let recordedBlob: Blob

        return new Promise(async (resolve, reject) => {
            let chunks: any = []

            // Define audio context
            const stream = await navigator.mediaDevices.getUserMedia({audio: true})
            audioContext.createMediaStreamSource(stream)

            // @ts-ignore - ts doesnt recognise MediaRecorder (but it is valid)
            // Define media recorder
            mediaRecorder = new MediaRecorder(stream)
            mediaRecorder.ondataavailable = (e: {data: any}) => chunks.push(e.data)
            mediaRecorder.onstop = () => {
                // Turn off the microphone
                stream.getTracks().forEach(track => track.stop())

                // Return the recording
                recordedBlob = new Blob(chunks, {'type': 'audio/ogg; codecs=opus'});
                chunks = [];
                resolve(recordedBlob)
            }

            // Start the recording
            mediaRecorder.start()

            // Limit to a max recording time
            setTimeout(() => {
                if (mediaRecorder.state === 'recording') {
                    mediaRecorder.stop()
                }
            }, maxTimeSec * 1000)
        })
    }

    const stop = () => {
        if (mediaRecorder.state === 'recording') {
            mediaRecorder.stop()
        }
    }

    return {
        record,
        stop,
    }
}
