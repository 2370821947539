// Third party imports
import React, { useEffect, useState } from 'react'
import { Card, CardContent, Icon, Typography, Fab, CardHeader, Button, Link } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { useConfirm } from 'material-ui-confirm'
import { useSwipeable } from 'react-swipeable'
import { setLocale, translate as t } from 'react-i18nify'
import { isBrowser, isMobile, isMobileOnly, isTablet, isSafari } from 'react-device-detect'

// Our imports
import { IHelpProps } from '../types/help-interfaces'
import Header from '../common/header'
import Toast from '../common/toast'
import * as configModel from '../common/config-model'
import * as audiogramsModel from '../audiograms/audiograms-model'
import * as soundTracksModel from '../sound-tracks/sound-track-model'
import useStyles from '../styles/help-styles'
import { logCentral } from '../common/logger'
import flagNL from './flag-nl.png'
import flagUK from './flag-uk.png'

const Help = (props: IHelpProps) => {
  const [toast, setToast] = useState('')
  const [isInstalled, setIsInstalled] = useState(false)
  const [userId, setUserId] = useState('')
  const [displayLanguageHelp, setDisplayLanguageHelp] = useState(false)

  const classes = useStyles()
  const confirm = useConfirm()
  const handlers = useSwipeable({
    onSwipedLeft: () => props.nav && props.nav('soundTracks'),
    onSwipedRight: () => props.nav && props.nav('audiograms'),
  })
  let whoAmIClickCount = 0

  // Op page load
  useEffect(() => {
    configModel.get('userId').then(userId => {
      setUserId(userId as string)
    })

    configModel.get('language').then(language => {
      setDisplayLanguageHelp(!Boolean(language))
    })

    logEvent('Page load', 'Help')
  }, [])

  // Install app
  useEffect(() => {
    const hasSeenSafari = localStorage.getItem('hasSeenSafari') === 'true'
    if (isSafari && !hasSeenSafari) {
      confirm({
        title: t('help.safariTitle'),
        description: t('help.safariDescription'),
        confirmationText: 'Ok',
      }).then(() => localStorage.setItem('hasSeenSafari', 'true'))
    }

    if (!isSafari && isMobile && props.installApp) {
      configModel.get('hasSeenCanInstall').then(response => {
        if (response) return

        confirm({
          title: t('help.installTitle'),
          description: t('help.installDescription'),
        }).then(() => configModel.set('hasSeenCanInstall', true))
      })
    }
  }, [confirm, props.installApp])

  const highlightMenu = () => {
    setToast(t('toast.useMenu'))
    setTimeout(() => {
      setToast('')
    }, 500)
  }

  const menuIcon = (
    <span className={classes.iconBorder} onClick={highlightMenu}>
      <Icon className={classes.iconInText}>menu</Icon>
    </span>
  )
  const vertMenuIcon = (
    <Icon className={classes.iconInText} color={'secondary'}>
      more_vert
    </Icon>
  )
  const playNormal = (
    <Icon className={classes.iconInText} color={'secondary'}>
      play_circle_filled
    </Icon>
  )
  const audiogramToggle = (
    <Icon fontSize={'large'} className={classes.iconInText} color={'secondary'}>
      toggle_off_outline
    </Icon>
  )

  const addIcon = (
    <Fab color="primary" className={`${classes.iconInText} ${classes.iconSmall}`} size={'small'}>
      <Icon>add</Icon>
    </Fab>
  )
  const saveIcon = (
    <Fab variant="extended" color="primary" size={'small'} className={`${classes.iconInText} ${classes.iconSmall}`}>
      Save
    </Fab>
  )
  const tipsIcon = (
    <Fab variant="extended" color="secondary" size={'small'} className={`${classes.iconInText} ${classes.iconSmall}`}>
      <HelpOutlineIcon />
    </Fab>
  )

  const handleInstall = () => {
    if (props.installApp && !isInstalled) {
      logEvent('Install app on device')

      if (props.installApp()) {
        setIsInstalled(true)
        setToast(t('toast.willBeInstalled'))
      }
    }
  }

  const handleLanguage = (language: 'nl' | 'en') => {
    setLocale(language)
    const promises = []
    promises.push(configModel.set('navToWhere', 'help'))
    promises.push(configModel.set('language', language))
    promises.push(audiogramsModel.replaceExamples())
    promises.push(soundTracksModel.replaceExamples())

    logEvent('Language selected', language)

    Promise.all(promises)
      .then(() => {
        window.location.reload()
      })
      .catch(() => {
        setToast('Error while setting language: ' + language)
      })
  }

  const whoAmI = () => {
    whoAmIClickCount += 1
    if (whoAmIClickCount > 4) {
      whoAmIClickCount = 0
      return confirm({
        title: 'Found it!',
        description: '42!',
      })
    }

    logEvent('Display Who Am I')

    return confirm({
      title: t('help.whoAmITitle'),
      description: t('help.whoAmIDescription'),
      confirmationText: 'Ok',
    })
  }

  const logEvent = (action: string, label?: string) => {
    logCentral('Help', action, label)
  }

  return (
    <div className={classes.root} {...handlers}>
      <div className={classes.rootLargeScreen}>
        <Header classes={classes} title={t('heading.help')} menuOrBack={'menu'} nav={props.nav} />

        <div className={classes.helpContainer}>
          <div className={classes.topLineContainer}>
            <Typography variant="body2" component="p">
              {t('help.thisIsHelp')}
            </Typography>
            <div>
              <img
                src={flagNL}
                className={classes.countryFlag}
                onClick={() => handleLanguage('nl')}
                alt={'Nederlands'}
              />
              <img src={flagUK} className={classes.countryFlag} onClick={() => handleLanguage('en')} alt={'English'} />
            </div>
          </div>

          <Typography variant="h5" component="h2" className={classes.contentTitle}>
            {t('general.appName')}
          </Typography>
          {!t('general.appNameSubtitle') ? null : (
            <Typography variant="subtitle2" className={classes.contentSubtitle}>
              {t('general.appNameSubtitle')}
            </Typography>
          )}

          <Card className={displayLanguageHelp ? classes.cardLanguage : classes.hidden}>
            <CardContent className={classes.contentHoriz}>
              <Typography variant="body1" component="p">
                {t('help.languageTitle')}
              </Typography>
              <img
                src={flagNL}
                className={classes.countryFlagLarge}
                onClick={() => handleLanguage('nl')}
                alt={'Nederlands'}
              />
              <img
                src={flagUK}
                className={classes.countryFlagLarge}
                onClick={() => handleLanguage('en')}
                alt={'English'}
              />
            </CardContent>
          </Card>

          <Typography variant="body2" component="p" paragraph>
            {t('help.listenToLike')}
          </Typography>

          <Card className={classes.cardOfMany}>
            <CardHeader className={classes.cardTitle} title={t('help.step1')} />
            <CardContent className={classes.content}>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.audiogramIs')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {menuIcon} {t('help.swipeToAudiograms')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {addIcon} {t('help.createAudiogram')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.dontForgetToSave')} {saveIcon}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {tipsIcon} {t('help.getTips')}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.cardOfMany}>
            <CardHeader className={classes.cardTitle} title={t('help.step2')} />
            <CardContent className={classes.content}>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {menuIcon} {t('help.swipeToSoundTracks')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {addIcon} {t('general.and')} {saveIcon} {t('help.addTrack')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {tipsIcon} {t('help.getTips')}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.cardOfMany}>
            <CardHeader className={classes.cardTitle} title={t('help.step3')} />
            <CardContent className={classes.content}>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {vertMenuIcon} {t('tips.nextToSoundTrack')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {playNormal} {t('tips.playNormal')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.use')} {audiogramToggle} {t('tips.playWith')}
              </Typography>
            </CardContent>
          </Card>

          <Card className={1 ? classes.cardOfMany : classes.hidden}>
            <CardHeader className={classes.cardTitle} title={t('help.installAsApp')} />
            <CardContent className={classes.content}>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('general.installing')} {isBrowser ? t('general.inYourBrowser') : ' '}
                {isMobileOnly ? t('general.onYourPhone') : ' '}
                {isTablet ? t('general.onYourTablet') : ' '}
                {t('help.has2Advantages')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.twoAdvantages')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.installTheApp')}
                <Fab
                  color="primary"
                  className={classes.marginLeft}
                  aria-label="Add To Home Screen"
                  onClick={handleInstall}
                >
                  <Icon>add_to_home_screen</Icon>
                </Fab>
              </Typography>
            </CardContent>
          </Card>

          <Card id="helpNotes" className={classes.cardOfMany}>
            <CardHeader className={classes.cardTitle} title={t('help.notesTitle')} />
            <CardContent className={classes.content}>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.audiogramsExport')} {vertMenuIcon}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.audiogramsSend')}
              </Typography>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.audiogramsImport')} {addIcon}
              </Typography>
            </CardContent>
          </Card>

          <Card className={isSafari ? classes.cardOfMany : classes.hidden}>
            <CardHeader className={classes.cardTitle} title={'Safari / iOS'} />
            <CardContent className={classes.content}>
              <Typography variant="body1" component="p" className={classes.contentText}>
                {t('help.safariTitle')}
                <br />
                {t('help.safariDescription')}
              </Typography>
            </CardContent>
          </Card>

          <div className={classes.footerContainer}>
            <br />
            <div className={classes.privacyPolicyContainer}>
              <Typography className={classes.footer} color="textSecondary" paragraph>
                {t('help.privacyHere')}
              </Typography>
              <Link href={'/privacy'} target="_blank" className={classes.privacyPolicyLink} color="secondary">
                privacy policy.
              </Link>
            </div>

            {userId ? (
              <Typography className={classes.footer} color="textSecondary" paragraph>
                {t('help.youAre')} {userId}
              </Typography>
            ) : null}

            <Typography className={classes.footer} color="textSecondary" paragraph>
              {t('help.supportAt')}
              <Button className={classes.whoAmI} size="small" variant="outlined" color="secondary" onClick={whoAmI}>
                {t('help.whoAmI')}
              </Button>
            </Typography>

            <Typography className={classes.footer} color="textSecondary" paragraph>
              <Icon className={classes.footer}>copyright</Icon>
              2023 All rights are retained by the developer.
              <br />
              This is version
              {' ' + process.env.REACT_APP_VERSION}
              {process.env.REACT_APP_ENVIRONMENT === 'production' ? '' : '-' + process.env.REACT_APP_ENVIRONMENT}.
            </Typography>
          </div>

          <Toast message={toast} horizontal={'left'} />
        </div>
      </div>
    </div>
  )
}

export default Help
