// Third party imports
import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { translate as t } from 'react-i18nify'

// Our imports
import { IPrivacyProps } from '../types/help-interfaces'
import Header from '../common/header'
import useStyles from '../styles/help-styles'

const PrivacyPolicy = (props: IPrivacyProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.rootLargeScreen}>
        <Header
          classes={classes}
          title={t('heading.privacy')}
          menuOrBack={'back'}
          nav={props.nav}
          onButtonClick={() => window.location.assign('/')}
        />

        <div className={classes.helpContainer}>
          <div className={classes.topLineContainer}>
            <Typography variant="body2" component="p">
              {t('help.thisIsPrivacy')}
            </Typography>
          </div>

          <Typography variant="h5" component="h2" className={classes.contentTitle}>
            {t('general.appName')}
          </Typography>
          {!t('general.appNameSubtitle') ? null : (
            <Typography variant="subtitle2" className={classes.contentSubtitle}>
              {t('general.appNameSubtitle')}
            </Typography>
          )}

          <Card className={classes.cardLanguage}>
            <CardContent className={classes.contentHoriz}>
              <Typography variant="body1" component="p">
                {t('help.privacyTitle')}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.cardLanguage}>
            <CardContent className={classes.contentHoriz}>
              <Typography variant="body2" component="p" paragraph>
                {t('help.privacyPolicy')}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
