import axios from 'axios'
import cryptoJs from 'crypto-js'
import ReactS3Client from 'react-aws-s3-typescript'
import constants from './constants'
import {IAudiogram} from '../types/audiograms-interfaces'

const collapseText = (text: string): string => {
  // Remove whitespace and make lowercase
  return text.replace(/\s/g, '').toLowerCase()
}

const generateS3UrlParts = (userId: string, password: string, folderOrFull: 'folder' | 'full'): string => {
  // Hash the userId
  const hashedUserId = cryptoJs.HmacSHA256(collapseText(userId), password)
  const folderPath = `${constants.SHORT_TERM_FOLDER}${process.env.REACT_APP_ENVIRONMENT}/${constants.AG_FOLDER}/${hashedUserId}`

  switch (folderOrFull) {
    case 'folder':
      return folderPath

    case 'full':
      const s3Root = `https://${constants.S3_BUCKET_NAME}.s3-${constants.S3_REGION}.amazonaws.com/`
      const fullPath = `${s3Root}${folderPath}/${constants.AG_FILE_NAME}.${constants.AG_FILE_TYPE}`
      return fullPath
  }

  return ''
}

const shareService = {
  retrieve: async (userId: string, password: string): Promise<IAudiogram[]> => {

    // Build the fileName on s3 and retrieve the file
    const fullPath = generateS3UrlParts(userId, password, 'full')
    const streamFile = async () => {
      try {
        const data = await axios.get(fullPath)
        return data.data
      } catch (error) {
        return ''
      }
    }
    const audiogramEncoded = await streamFile()

    const audiograms = shareService.decode(audiogramEncoded)

    return (audiograms && audiograms.length > 0) ? Promise.resolve(audiograms) : Promise.reject('Error retrieving audiogram')
  },

  send: async (userId: string, password: string, audiogram: IAudiogram): Promise<string> => {
    // Is there an existing shared audiogram?
    let audiogramsArray: IAudiogram[] = []
    try {
      audiogramsArray = await shareService.retrieve(userId, password)
    } catch (error) {
      // Do nothing - there prob were no existing audiograms
    }
    audiogramsArray.push(audiogram)

    // Create a file and upload to S3
    const fileContent = shareService.encode(audiogramsArray)
    const folderPath = generateS3UrlParts(userId, password, 'folder')
    const fileName = constants.AG_FILE_NAME
    const s3Config = {
      bucketName: constants.S3_BUCKET_NAME,
      region: constants.S3_REGION,
      accessKeyId: constants.S3_ACCESS_KEY,
      secretAccessKey: constants.S3_SECRET,
    }

    try {
      const s3 = new ReactS3Client({
        ...s3Config,
        dirName: folderPath,
      });
      const fileTypeMime = `text/${constants.AG_FILE_TYPE}`
      const file = new File([fileContent], fileName, {type: fileTypeMime})
      await s3.uploadFile(file, fileName);
      return Promise.resolve('')

    } catch (error) {
      return Promise.reject(error)
    }
  },

  // Encode an array of audiograms
  encode: (audiograms: IAudiogram[]): string => {
    const toExportArray: IAudiogram[] = []

    // Remove the ids
    audiograms.forEach(audiogram => {
      const toExport = Object.assign({}, audiogram, {id: undefined})
      delete toExport.id
      toExportArray.push(toExport)
    })
    try {
      return window.btoa(JSON.stringify(toExportArray))
    } catch (error) {
      return ''
    }
  },

  // Decode a string to array of audiograms
  decode: (codedAudiograms: string): IAudiogram[] => {
    try {
      return JSON.parse(window.atob(codedAudiograms))
    } catch (error) {
      return []
    }
  },
}

export default shareService

