// Third party imports
import React, { useEffect, useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import AccountCircle from '@material-ui/icons/AccountCircle'
import NotificationsIcon from '@material-ui/icons/Notifications'
import { AppBar, Badge, Icon, IconButton, Menu, MenuItem, Toolbar, Typography } from '@material-ui/core'
import { IHeaderProps } from '../types/sound-tracks-interfaces'
import { useConfirm } from 'material-ui-confirm'
import { translate as t } from 'react-i18nify'

// Our imports
import useStyles from '../styles/common-styles'
import * as configModel from '../common/config-model'
import Toast from '../common/toast'
import IdentityPassword, { ValidateUserId } from '../common/identity-password'
import notificationsService from './notifications-service'
import { decideFlag } from './decide-flag'
import { useFlag } from 'react-unleash-flags'

const Header = (props: IHeaderProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [userId, setUserId] = useState('')
  const [toast, setToast] = useState('')
  const [displayIdentityContainer, setDisplayIdentityContainer] = useState(false)
  const [messages, setMessages] = useState<string[]>([])
  const [isAdmin, setIsAdmin] = useState(false)
  const confirm = useConfirm()

  let profileClickCount = 0

  // Load the admin flag
  const isAdminFlag = decideFlag(useFlag('show-admin-menu'))
  isAdminFlag.then(flag => setIsAdmin(flag))

  // On load get the userId
  useEffect(() => {
    setToast('')
    configModel.get('userId').then(userId => {
      setUserId(userId as string)
    })
  }, [])

  // For the userId, get any notifications
  useEffect(() => {
    setToast('')
    notificationsService
      .retrieve(userId, '')
      .then(_messages => setMessages(_messages))
      .catch(error => {
        // Do nothing - there were no notifications
      })
  }, [userId])

  const onMenuClick = (event: any) => {
    setToast('')
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setToast('')
    setAnchorEl(null)
  }

  const handleProfile = () => {
    profileClickCount += 1
    if (profileClickCount === 1 && userId) {
      // Display the userId
      setToast(t('heading.youAre', { name: userId }))
    }

    if (displayIdentityContainer) {
      profileClickCount = 0
      setDisplayIdentityContainer(false)
    } else {
      if (profileClickCount > 2) {
        profileClickCount = 0
        setDisplayIdentityContainer(true)
        configModel.get('userId').then(_userId => {
          if (_userId) {
            setUserId(_userId as string)
          }
        })
      }
    }
  }

  const handleSetUserId = (value: string) => {
    setToast('')
    if (!value || ValidateUserId(value)) {
      setUserId(value)
      configModel.set('userId', value)
    } else {
      setToast(t('heading.identityLonger'))
    }
  }

  const handleNotification = () => {
    const description = messages.map(m => <p>{m}</p>)

    confirm({
      title: `${messages.length} Message${messages.length > 1 ? 's' : ''}`,
      description,
    }).then(() => {
      // Have read the messages, so note the timestamp we read them
      setMessages([])
      notificationsService.delete(userId, '')
      configModel.set('hasSeenWhatsNew', new Date().toISOString())
    })
  }

  const navTo = (where: string) => {
    handleClose()
    props.nav && props.nav(where)
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
          id="mainMenu"
          edge="start"
          className={props.classes.menuButton}
          color="inherit"
          aria-label={props.menuOrBack === 'menu' ? 'Menu' : 'Back'}
          onClick={props.menuOrBack === 'menu' ? onMenuClick : props.onButtonClick}
        >
          {props.menuOrBack === 'menu' ? <MenuIcon /> : <Icon>arrow_back</Icon>}
        </IconButton>
        <Typography variant="h6" className={props.classes.title}>
          {props.title}
        </Typography>
        {messages.length ? (
          <MenuItem onClick={handleNotification}>
            <IconButton edge="end" aria-label="Notifications" color="inherit">
              <Badge badgeContent={messages.length} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </MenuItem>
        ) : null}
        <MenuItem onClick={handleProfile}>
          <IconButton edge="end" aria-label="Identity of current user" aria-haspopup="true" disabled={!Boolean(userId)}>
            {userId ? userId.slice(0, 1).toUpperCase() : <AccountCircle />}
          </IconButton>
        </MenuItem>
      </Toolbar>

      <Menu id="mainMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => navTo('soundTracks')}>{t('heading.soundTracks')}</MenuItem>
        <MenuItem onClick={() => navTo('audiograms')}>{t('heading.audiograms')}</MenuItem>
        <MenuItem onClick={() => navTo('help')}>{t('heading.help')}</MenuItem>
        {!isAdmin ? null : <MenuItem onClick={() => navTo('admin')}>{t('heading.admin')}</MenuItem>}
      </Menu>

      {displayIdentityContainer ? (
        <div className={classes.identityContainer}>
          <Typography className={classes.identityText}>
            {t('heading.identityToReceive')}
            {userId ? t('heading.youAre', { name: userId }) : ''}
          </Typography>
          <IdentityPassword userId={userId} onChangeIdentity={handleSetUserId} />
        </div>
      ) : null}

      <Toast message={toast} />
    </AppBar>
  )
}

export default Header
