import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import Chart from 'react-google-charts'
import Tips from "../help/tips"
import * as model from './audiograms-model'
import {translate as t} from 'react-i18nify'

export const AudiogramGraph = (props) => {
  const [currentChartData, setCurrentChartData] = useState([])
  const [currentOptions, setCurrentOptions] = useState({})
  const [currentEvents, setCurrentEvents] = useState({})

  const {currentPoint, graphWidth, onSelect, data: givenData} = props


  const FREQUENCY_SERIES_0 = 0
  const EDGE_SERIES_1 = 1
  // const LOSS_SERIES_2 = 2      // Not required but just filling gaps
  const SETBYUSER_SERIES_3 = 3
  // const SETTINGNOW_SERIES_4 = 4
  const FIRST_TARGET_SERIES = 5
  const LAST_TARGET_SERIES = 29

  const setTargetDots = (_chartData, _chartOptions) => {
    const currentIndex = _chartData.findIndex(pointArray => pointArray[FREQUENCY_SERIES_0] === currentPoint.frequency.toString())

    for (let seriesIndex = 0; seriesIndex <= (LAST_TARGET_SERIES - FIRST_TARGET_SERIES); seriesIndex++) {
      const targetLoss = seriesIndex * 5
      // Add to chartData series names
      _chartData[0][FIRST_TARGET_SERIES + seriesIndex] = `Target${targetLoss}`

      // Add to chartData data for all dots
      for (let dataIndex = 1; dataIndex < _chartData.length; dataIndex++) {
        _chartData[dataIndex][FIRST_TARGET_SERIES + seriesIndex] = null
      }

      // Add to chartData data for current dot
      _chartData[currentIndex][FIRST_TARGET_SERIES + seriesIndex] = targetLoss

      // Add to chartData options
      _chartOptions.series[FIRST_TARGET_SERIES + seriesIndex - 1] = {color: 'greenyellow', lineWidth: 0, pointSize: 5}
    }
  }

  const avoidNullSeries = (_chartData) => {
    // Check if chartdata contains a series of nulls only - because that's not allowed
    let currentFrequencyIndex = 0
    for (let series = SETBYUSER_SERIES_3; series <= LAST_TARGET_SERIES; series++) {
      let thisSeriesHasData = false
      // eslint-disable-next-line
      _chartData.forEach((xMark, index) => {
        if (index === 0) {
          return
        }
        thisSeriesHasData = thisSeriesHasData || (xMark[series] !== null)
        if (parseInt(xMark[0]) === currentPoint.frequency) {
          currentFrequencyIndex = index
        }
      })
      // For a series with no data => create fake data behind the big current (green) dot
      if (!thisSeriesHasData) {
        _chartData[currentFrequencyIndex][series] = _chartData[currentFrequencyIndex][EDGE_SERIES_1]
      }
    }
  }

  // Setup the chart when we receive data
  useEffect(() => {
    const xAxisMarks = Object.keys(givenData)
    if (xAxisMarks.length < 1) {
      return
    }

    const handleChartSelect = ({chartWrapper}) => {
      const chart = chartWrapper.getChart()
      const selection = chart.getSelection()
      if (selection.length === 1) {
        const [selectedItem] = selection
        const dataTable = chartWrapper.getDataTable()
        const {row, column} = selectedItem
        if (typeof onSelect === 'function') {
          const frequencyMark = _.find(model.frequencies, f => f.value === row + 1)
          onSelect(frequencyMark, dataTable.getValue(row, column))
        }
      }
    }

    let chartData = []

    // Set the data array
    let isEdge
    let fromEdgeToMain = false
    xAxisMarks.map((freq, index) => {
      const data = givenData[freq]
      if (index === 0) {
        // Just starting so set up the titles row of the chartData array
        chartData.push(['Frequency', 'Edge', 'Loss', 'SetByUser', 'SettingNow'])
        isEdge = true
      }

      if (data.edgeData !== isEdge) fromEdgeToMain = true

      chartData.push([
        freq,
        data.loss,                             // Series 1 = edge grey graph
        fromEdgeToMain ? data.loss : null,     // Series 2 = main red graph
        data.setByUser ? data.loss : null,     // Series 3 = red dots
        data.settingNow ? data.loss : null,    // Series 4 = large current dot
      ])
      fromEdgeToMain = false
      return true
    })

    // Set the options
    const width = Math.min(graphWidth, window.innerWidth)
    const chartOptions = {
      width: width,
      height: width,
      backgroundColor: 'white',
      legend: {
        position: 'none',
      },
      tooltip: {
        trigger: 'none'
      },
      lineWidth: 3,
      series: {
        0: {color: 'grey', lineWidth: 3, pointSize: 0},
        1: {color: 'red', lineWidth: 3, pointSize: 0},
        2: {color: 'red', lineWidth: 0, pointSize: 5},
        3: {color: 'greenyellow', lineWidth: 0, pointSize: 15},
      },
      hAxis: {
        title: t('general.frequency'),
        minValue: 125,
        maxValue: 8000,
        logscale: true,
      },
      vAxis: {
        title: t('general.loss'),
        minValue: 0,
        maxValue: 120,
        direction: -1,
        ticks: [0, 20, 40, 60, 80, 100, 120],
      }
    }

    setTargetDots(chartData, chartOptions)
    avoidNullSeries(chartData)
    setCurrentChartData(chartData)
    setCurrentOptions(chartOptions)

    // Set the click event
    const chartEvents = [{
      eventName: 'select',
      callback: handleChartSelect,
    }]
    setCurrentEvents(chartEvents)

    // eslint-disable-next-line
  }, [currentPoint.frequency, givenData, graphWidth, onSelect])

  if (Object.keys(givenData).length < 1) {
    return (
      <div>
        <br/>
        <Tips tips={['addPointAudiogram']}/>
      </div>
    )
  }

  console.groupCollapsed('Google Charts errors')
  console.error('The "TypeError: Converting circular structure to JSON" errors below seem to originate in the react-google-charts library :-(')
  const chart = (
    <Chart
      chartType='LineChart'
      loader={<div>Loading audiogram</div>}
      data={currentChartData}
      options={currentOptions}
      chartEvents={currentEvents}
    />
  )
  console.groupEnd()

  return chart
}
