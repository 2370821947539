import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    adminContainer: {
      overflow: 'auto',
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
    hidden: {
      display: 'none',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1,
    },
    panelRoot: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    listRoot: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
    libraryListItem: {
      display: 'flex',
      alignItems: 'space-between',
    },
    playBtnContainer: {
      maxWidth: '20%',
    },
    topLineContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
    },
    addMessage: {
      fontSize: 'small',
      fontStyle: 'italic',
      color: theme.palette.primary.main,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
    whatsnewText: {
      width: '100%',
      fontSize: 'small',
      lineHeight: 0.8,
    },
    textField: {
      // important required to prevent prod minimisation strangeness
      marginLeft: theme.spacing(2) + 'px!important',
      marginRight: theme.spacing(2),
      marginTop: 0,
      marginBottom: 0,
      width: '92%',
    },
    '@media (min-width: 1224px)': {},
  }
})

export default useStyles
