import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    rootLargeScreen: {},
    helpContainer: {
      height: '100%',
      overflow: 'auto',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      backgroundColor: 'white',
    },
    hidden: {
      display: 'none',
    },
    countryFlag: {
      width: 20,
      marginLeft: theme.spacing(1),
    },
    countryFlagLarge: {
      width: 40,
      marginLeft: theme.spacing(4),
    },
    topLineContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginRight: theme.spacing(2),
    },
    oneCard: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.secondary.light,
    },
    cardOfMany: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      backgroundColor: theme.palette.secondary.light,
    },
    cardLanguage: {
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.light,
    },
    title: {
      flexGrow: 1,
    },
    cardTitle: {
      textDecoration: 'underline',
    },
    footer: {
      fontSize: 12,
    },
    iconInText: {
      verticalAlign: 'middle',
    },
    iconSmall: {
      height: 15,
      opacity: 0.5,
    },
    iconBorder: {
      height: 15,
      width: 'fit-content',
      display: 'inline',
      padding: 4,
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      opacity: 0.5,
    },
    marginLeft: {
      marginLeft: theme.spacing(1),
    },
    paddingRight: {
      paddingRight: theme.spacing(1),
    },
    contentHoriz: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1) + 'px !important',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
    contentText: {
      paddingBottom: 15,
    },
    tipContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(1),
      paddingBottom: 0,
    },
    contentTitle: {
      textAlign: 'center',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    contentSubtitle: {
      textAlign: 'center',
      paddingBottom: theme.spacing(3),
      fontWeight: 'bold',
    },
    oneTip: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 12,
    },
    whoAmI: {
      padding: 4,
      marginLeft: 4,
    },
    textField: {
      // important required to prevent prod minimisation strangeness
      marginLeft: theme.spacing(2) + 'px!important',
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
      width: '92%',
    },
    footerContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    privacyPolicyContainer: {
      display: 'flex',
      justifyItems: 'center',
    },
    privacyPolicyLink: {
      marginLeft: 4,
      marginBottom: 16,
      fontSize: 14,
    },
    '@media (min-width: 1024px)': {
      root: {
        backgroundColor: 'lightgrey',
      },
      rootLargeScreen: {
        width: 984,
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        margin: theme.spacing(6),
      },
      cardOfMany: {
        display: 'inline-block',
        width: '45%',
        minHeight: 420,
      },
      footerContainer: {
        backgroundColor: 'white',
      },
    },
  }
})

export default useStyles
