import localforage from 'localforage'
import { localforageConfig } from './localforage-config'

localforageConfig()
const STORE_PREFIX = 'config.'

const get = async (key: string) => {
  return localforage.getItem(STORE_PREFIX + key).then(value => {
    return value
  })
}

const getBase = async () => {
  return localforage.getItem(STORE_PREFIX + 'base').then(config => {
    return {
      ...config,
      isFirstStart: config ? false : true,
    }
  })
}

const set = async (key: string, value: any) => {
  return localforage.setItem(STORE_PREFIX + key, value)
}

const save = async (data: any) => {
  return localforage.setItem(STORE_PREFIX + 'base', data)
}

const remove = async (key: string) => {
  return localforage.removeItem(STORE_PREFIX + key)
}

export { get, set, getBase, save, remove }
