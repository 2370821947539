// Third party
import React from 'react'
import ReactDOM from 'react-dom'
import _ from 'lodash'
import createVirtualAudioGraph, { analyser, gain, biquadFilter, bufferSource } from 'virtual-audio-graph'
import { translate as t } from 'react-i18nify'

// Our imports
import { ISoundTrack, ISoundTrackProperties } from '../types/sound-tracks-interfaces'
import { IAudiogram, IOscilloscopeData } from '../types/audiograms-interfaces'
import * as model from './sound-track-model'
import { handleError } from '../common/handle-error'
import Chart from 'react-google-charts'

const fileReader = new FileReader()
let audioContext: any
const unlockAudioContext = (audioContext: AudioContext) => {
  if (audioContext.state !== 'suspended') {
    return
  }

  // Some browsers require user interaction to allow audio
  const b = document.body
  const events = ['touchstart', 'touchend', 'mousedown', 'keydown']
  events.forEach(e => b.addEventListener(e, unlock, false))

  function unlock() {
    audioContext.resume().then(clean)
  }

  function clean() {
    events.forEach(e => b.removeEventListener(e, unlock))
  }
}

// Get an audioContext
try {
  // @ts-ignore
  audioContext = new window.AudioContext()
} catch (response) {
  /*ignore this error and try again*/
}
if (!audioContext) {
  try {
    // @ts-ignore
    audioContext = new window.webkitAudioContext()
  } catch (response) {
    throw new Error('In this browser: no audioContext available')
  }
}
unlockAudioContext(audioContext)
const virtualAudioGraph = createVirtualAudioGraph({ audioContext })

let nodeAnalyser: any
let timerId: any

const NODE_LAST_FILTER = 90

export const player = (
  trackPlaying: ISoundTrack,
  setTrackPlaying: React.Dispatch<React.SetStateAction<ISoundTrack>>,
  setShowQualityFilters: (arg0: boolean) => void,
  soundTracks: ISoundTrack[],
  audiograms: IAudiogram[],
) => {
  const stop = () => {
    setTrackPlaying({})
    setShowQualityFilters(false)
    virtualAudioGraph.update({})
    draw(false)
  }

  const play = (soundTrackId: string, playWithFilter: boolean, elementId: string) => {
    // console.log('playTrack', soundTrackId, playWithFilter, elementId)

    // Same track?
    if (soundTrackId === trackPlaying.id) {
      if (playWithFilter === trackPlaying.playFiltered) {
        // Same track playing with same filter, so: stop
        stop()
        return
      } else {
        // Same track playing with different filter, so: add or remove the filer
        const nodeFiltered = virtualAudioGraph.getAudioNodeById(NODE_LAST_FILTER + 1)
        const nodeUnfiltered = virtualAudioGraph.getAudioNodeById(NODE_LAST_FILTER + 2)
        nodeFiltered && (nodeFiltered.gain.value = playWithFilter ? 1 : 0)
        nodeUnfiltered && (nodeUnfiltered.gain.value = playWithFilter ? 0 : 1)

        trackPlaying.playFiltered = playWithFilter
        setTrackPlaying(trackPlaying)
        setShowQualityFilters(playWithFilter ? true : false)

        return
      }
    }

    // Different track, so: stop the current track
    stop()

    // Find it
    let soundTrack = _.find(soundTracks, { id: soundTrackId }) as ISoundTrack
    if (!soundTrack.file) return

    // Start the new track, i.e. setup the variables
    soundTrack.playFiltered = playWithFilter
    setTrackPlaying(soundTrack)
    setShowQualityFilters(playWithFilter ? true : false)

    // Show a loader
    const addToElement = document.getElementById(elementId)
    if (addToElement) ReactDOM.render([<p style={{ textAlign: 'center' }}>Loading ...</p>], addToElement)

    // Really start the track by: after reading file soundtrack file....
    fileReader.onload = event => {
      if (!event) return null

      const arrayBuffer = fileReader.result
      if (!arrayBuffer || typeof arrayBuffer === 'string') {
        handleError('arrayBuffer not expected format:', arrayBuffer)
        return null
      }

      // ... convert the arrayBuffer to an audioBuffer, and ....
      audioContext.decodeAudioData(
        arrayBuffer,
        async (buffer: AudioBuffer) => {
          const filters = generateFilters(soundTrack.audiogramId, audiograms)
          console.log('filters', filters)

          // ... play (un)filtered
          virtualAudioGraph.update({
            0: gain('output', { gain: 1 }), // Node 0 = output
            1: analyser(0, {
              // Node 1 = fft analyser
              fftSize: 256,
              minDecibels: -200,
              maxDecibels: 0,
              smoothingTimeConstant: 0,
            }),

            ...filters, // Filtering nodes 2 thru max NODE_LAST_FILTER

            91: gain(NODE_LAST_FILTER, { gain: playWithFilter ? 1 : 0 }), // Node key must = NODE_LAST_FILTER + 1
            92: gain(1, { gain: !playWithFilter ? 1 : 0 }), // Node key must = NODE_LAST_FILTER + 2
            93: bufferSource(
              [
                // Node key must = NODE_LAST_FILTER + 3
                NODE_LAST_FILTER + 1,
                NODE_LAST_FILTER + 2,
              ],
              { buffer },
            ),
          })

          // Start the visualisation
          draw(true, elementId, soundTrack)

          // While we still have the nodes: set up a listener for end of track
          /* This had been removed because the event comes in too late, i.e. after the next track
                       has started playing or we have gone from unfiltered to filtered. In that case the late event
                       causes the new track (that is still playing) to be displayed as stopped
                    const nodeSource = virtualAudioGraph.getAudioNodeById(NODE_LAST_FILTER + 3)
                    nodeSource.addEventListener('ended', () => {
                        setTrackPlaying({})
                        setShowQualityFilters(false)
                        draw(false)
                    })
                    */

          // While we still have the nodes: define the analyser node for the oscilloscope
          nodeAnalyser = virtualAudioGraph.getAudioNodeById(1)
        },
        (response: DOMException) => {
          handleError('audio decode error', response)
        },
      )
    }

    // ... start the actual file read
    fileReader.readAsArrayBuffer(soundTrack.file)
  }

  const generateFilters = (audiogramId: string | undefined, audiograms: IAudiogram[]) => {
    const neutral = {
      90: gain(1, { gain: 1 }), // KEY MUST EQUAL NODE_LAST_FILTER
    }

    if (!audiogramId || !audiograms || audiograms.length === 0) return neutral

    let audiogram = _.find(audiograms, { id: audiogramId }) as IAudiogram

    if (!audiogram || !audiogram.points) return neutral

    const filters: any = {}
    const freqs = Object.keys(audiogram.points)
    const nrOfPoints = freqs.length
    freqs.map((f, freqIndex) => {
      let key = freqIndex === nrOfPoints - 1 ? NODE_LAST_FILTER : freqIndex + 2
      let prevKey = freqIndex + 1

      const freq: number = parseInt(f)

      filters[key] = biquadFilter(prevKey, {
        type: 'peaking',
        frequency: freq,
        gain: audiogram.points && audiogram.points[freq].loss * -1,
        Q: model.qDefault,
        detune: model.detuneDefault,
      })

      return null
    })

    return filters
  }

  const update = (trackplaying: ISoundTrack, newValues: ISoundTrackProperties) => {
    let audiogram = _.find(audiograms, { id: trackplaying.audiogramId }) as IAudiogram
    if (!audiogram || !audiogram.points) return

    const freqs = Object.keys(audiogram.points)
    const nrOfPoints = freqs.length

    // Update all freq points with new values for Q or detune
    freqs.map((f, freqIndex) => {
      let key = freqIndex === nrOfPoints - 1 ? NODE_LAST_FILTER : freqIndex + 2
      const nodeFilter = virtualAudioGraph.getAudioNodeById(key)

      // Expect params to be Q | detune
      const params = Object.keys(newValues)
      params.map(param => {
        nodeFilter[param].value = newValues[param]

        return true
      })
      return true
    })
  }

  const draw = (start: boolean = true, elementId: string | null = null, soundtrack: ISoundTrack | null = null) => {
    if (!start) {
      clearInterval(timerId)
      return
    }

    let bufferLength: number
    let dataArray: any
    let sliceSizeHz: number
    let audiogram: IAudiogram

    // Find the audiogram
    if (soundtrack && soundtrack.audiogramId) {
      audiogram = _.find(audiograms, { id: soundtrack.audiogramId }) as IAudiogram
    }

    // Redraw every 500ms
    timerId = setInterval(() => {
      if (!nodeAnalyser) return

      bufferLength = nodeAnalyser.frequencyBinCount
      dataArray = new Uint8Array(bufferLength)
      nodeAnalyser.getByteFrequencyData(dataArray)
      sliceSizeHz = nodeAnalyser.context.sampleRate / nodeAnalyser.fftSize / 2

      if (elementId) drawOscilloscope(dataArray, sliceSizeHz, elementId, audiogram, soundtrack || {})
    }, 500)
  }

  const drawOscilloscope = (
    dataArray: IOscilloscopeData[],
    xSize: number,
    elementId: string,
    audiogram: IAudiogram,
    soundtrack: ISoundTrack,
  ) => {
    let chartData = []
    chartData.push([t('soundTracks.frequency'), t('soundTracks.soundDb'), t('soundTracks.audiogram')])

    // Set the data array to max number of slices (96 slices) = 8kHz
    let hz, strength, agLoss
    dataArray.map((freqIndex, index) => {
      if (index > 95) return false
      hz = Math.round(index * xSize)
      strength = Math.min(200, dataArray[index] as unknown as number)
      agLoss = 0
      if (audiogram && audiogram.points) {
        if (hz <= 200) {
          agLoss = audiogram.points[125].loss
        } else if (hz <= 375) {
          agLoss = audiogram.points[250].loss
        } else if (hz <= 750) {
          agLoss = audiogram.points[500].loss
        } else if (hz <= 1250) {
          agLoss = audiogram.points[1000].loss
        } else if (hz <= 1750) {
          agLoss = audiogram.points[1500].loss
        } else if (hz <= 3000) {
          agLoss = audiogram.points[2000].loss
        } else if (hz <= 5000) {
          agLoss = audiogram.points[4000].loss
        } else if (hz <= 7000) {
          agLoss = audiogram.points[6000].loss
        } else {
          agLoss = audiogram.points[8000].loss
        }
      }
      chartData.push([hz, strength, agLoss !== undefined ? 200 - agLoss : undefined])
      return true
    })

    // Set the options
    const options = {
      enableInteractivity: false,
      backgroundColor: 'white',
      legend: {
        position: 'bottom',
      },
      series: {
        0: { lineWidth: 3, color: 'green' },
        1: { lineWidth: 1, color: 'red' },
      },
      title: `${soundtrack.name}${
        soundtrack.playFiltered ? t('soundTracks.withAudiogram') : t('soundTracks.withoutAudiogram')
      }`,
      hAxis: {
        title: t('soundTracks.frequencyHz'),
        ticks: [1000, 2000, 4000, 6000, 8000],
        logscale: true,
        gridlines: {
          color: '#fff',
        },
      },
      vAxis: {
        title: 'dB',
        ticks: [],
        minValue: 0,
        maxValue: 200,
      },
    }

    const oscilloscopeGraph = (
      <Chart
        chartType="LineChart"
        loader={<div style={{ textAlign: 'center', marginTop: 16 }}>Loading oscilloscope...</div>}
        data={chartData}
        options={options}
      />
    )

    const addToElement = document.getElementById(elementId)

    if (addToElement) ReactDOM.render([oscilloscopeGraph], addToElement)
  }

  return {
    stop,
    play,
    update,
  }
}
