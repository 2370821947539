// Env variables found here: process.env

const constants = {
  STRING_SEPARATOR: '-----',
  SHORT_TERM_FOLDER: 'short-term/',   // include / or empty string
  WHATSNEW_FOLDER: 'whatsnew',
  NOTIFICATIONS_FOLDER: 'notifications',
  NOTIFICATIONS_FILE_NAME: 'notifications',
  NOTIFICATIONS_FILE_TYPE: 'txt',
  LIBRARY_FOLDER: 'library',
  LIBRARY_FILE_NAME: 'library-contents',
  LIBRARY_FILE_TYPE: 'json',
  AG_FOLDER: 'audiograms',
  AG_FILE_NAME: 'audiograms',
  AG_FILE_TYPE: 'txt',
  S3_BUCKET_NAME: 'hearingloss',
  S3_REGION: 'ap-southeast-2',
  S3_ACCESS_KEY: process.env.REACT_APP_AWS_ACCESS_KEY_ID || '',
  S3_SECRET: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY || '',
}

export default constants
