import React from 'react'
import { Icon } from '@material-ui/core'

const allLanguages = {
  en: {
    general: {
      appName: 'Hearing Loss Sounds Like',
      appNameSubtitle: '',
      use: 'Use',
      and: 'and',
      dontForget: "Don't forget to",
      installing: 'installing',
      inYourBrowser: 'in your browser',
      onYourPhone: 'on your phone',
      onYourTablet: 'on your tablet',
      add: 'Add',
      edit: 'Edit',
      build: 'Build',
      send: 'Send',
      save: 'Save',
      delete: 'Delete',
      reset: 'Reset',
      receive: 'Receive',
      frequency: 'Frequency',
      loss: 'Loss',
      title: 'Title',
      wantToUpdate: '"Hearing Loss Sounds Like" wants to update. Please fully close this app and restart',
      updateReminder: "Don't forget to update - please fully close this app and restart",
    },
    heading: {
      soundTracks: 'Sound Tracks',
      audiograms: 'Audiograms',
      help: 'Help',
      privacy: 'Privacy Policy',
      youAre: 'You are %{name}',
      identityLonger: 'De identiteit moet langer zijn.',
      identityToReceive: 'To be able to receive audiograms, we need to identify you.',
    },
    identity: {
      yourIdentity: 'Your identity, e.g. your email address',
      password: 'The password for the audiogram',
    },
    toast: {
      usePlus: 'Please use the actual + icon at the bottom right of the list',
      useMenu: 'Please use the actual menu icon behind me',
      willBeInstalled: 'The Hearing Loss Sounds Like app will be installed',
      chooseLanguage: 'Please choose your language',
    },
    tips: {
      use: 'Use',
      toSelectAudiogram: 'to select an Audiogram',
      createAudiogramUsing: 'Create an Audiogram using the menu',
      buttonOrSwipe: 'button, or swipe to Audiograms.',
      addSoundtrack: 'to record, get music from the library or add your own',
      nextToSoundTrack: 'next to a sound track.',
      playNormal: 'to play normally (without the Audiogram)',
      playWith: 'to include the effect of the Audiogram',
      toAddAudiogram: 'to add an Audiogram',
      sendAudiogramToMe: 'Has somebody already built your audiogram? They can send it to you',
      moreOptions: 'for more options',
      clickToChange: 'Move the sliders to change the audiogram. Not too fast, please.',
      addPointsDescription:
        'An audiogram has 2 axes. The vertical axis is measured in decibels, that represents the volume. ' +
        "At the top is 0 db, the level at which most people don't hear. At bottom is 120 db, that is very, very loud. " +
        'The horizontal axis is measured in Hertz and kiloHertz and represents the frequency (or pitch). ' +
        'To the right are higher frequencies (so higher pitch).',
      addPointsInstruction:
        'Find the values of the points in your audiogram. Click in the audiogram on the line or on the dots. ' +
        'Or, move the sliders to those values to enter that point. ' +
        'For example, if your audiogram shows at 1000 Hertz (or 1 kiloHertz) a point at 30 decibel, ' +
        'move the upper slider to 1 kHz and lower slider to 30 dB.',
      senderGivesDetails: 'The sender must give you these details to receive an audiogram',
      clickReceive: 'Then click on Receive button below',
      chooseIdentity: 'Choose an identity for the receiver and a password',
      giveDetails: 'Give these details to the receiver',
      availablePeriod: 'It will remain available for 1 week',
      exportAudiogram: 'Import this code when adding a new audiogram',
      sendCode: 'Please send this code through email or sms',
      copiedClipboard: 'This code has already been copied to the clipboard!',
      playFilteredMeans:
        'What does this mean? The sound you are hearing now is only an approximation of what the hearing impaired person hears. ' +
        'Please note: background noise has a huge impact for the hearing impaired - a much larger impact than for yourself. ' +
        "Also: depending on the impairment, it's possible that 1 ear is better the other",
    },
    help: {
      languageTitle: 'Language choice:',
      privacyTitle: 'Privacy Policy (English)',
      safariTitle: 'Are you using Safari?',
      safariDescription:
        'It looks like you are using the Safari browser. ' +
        'Some versions of Safari and relevant settings have problems with the audio packages. ' +
        'If you into run into problems like sound tracks stopping, no sound at all or "IDB" problems, ' +
        'please use one of the other major browsers. We suggest Chrome or Edge.',
      installTitle: 'Install the app on mobile',
      installDescription:
        'It looks like you are on a mobile device. ' +
        'Hearing Loss Sounds Like can be installed as an app. ' +
        'This has 2 advantages: 1) works without internet, ' +
        'and 2) - easier updates. ' +
        'Please see the help section to install as an app.',
      whoAmI: 'Who am I?',
      whoAmITitle: 'Who am I and why make this app?',
      whoAmIDescription:
        'My daughter has a very significant hearing loss where practically only 1 ear participates ' +
        'and at quite a low level. ' +
        "It's very hard for anybody - including myself - to get a good understanding of what that means on a daily basis. " +
        'This app just tries to give some insight into what it means to be hearing impaired. ' +
        'Please realise that the actual implications go wel beyond what this app can demonstrate. ' +
        'Thanks for trying! Mark.',
      thisIsHelp: 'This is the Help page for',
      thisIsPrivacy: 'This is the Privacy Policy page for',
      listenToLike: (
        <span>
          Listen to a sound track as a hearing impaired person hears it. Please realise that it's impossible to
          accurately reproduce human (lack of) hearing.
          <br />
          <br />
          The human ear and brain are extremely clever - all current hearing aids and technologies are a reasonable
          alternative, but they will never fully completely compensate for human hearing loss.
          <br />
          <br />
          This app simulates transmission loss only and can only give you a glimpse into the world and challenges of the
          hearing impaired.
        </span>
      ),
      privacyPolicy: (
        <span>
          This privacy policy governs the use of the software application “Hearing Loss Sounds Like” (referred as the
          “Application”).
          <br />
          <br />
          The Application is a Web and Android application intended to give users an indication of what a hearing
          impaired person hears.
          <br />
          <br />
          The Application does not require any user registration nor any personal information.
          <br />
          <br />
          In the specific case only when a user wishes to send an audiogram to another user, the user is requested to
          provide a random but unique identifier. An example is given of an email address. The Application only stores
          the identifier locally on the device. A one-way encrypted version of the identifer is stored remotely for
          maximum 1 week to allow sharing/sending of the audiograam. The Application does not collect any other type of
          personal information. The user's identifier can be deleted by the user at any time.
          <br />
          <br />
          For any questions regarding privacy. please contact the developer via email at: mark@filius.cc
          <br />
          <br />
          Last updated: 8 Jan 2024
          <br />
        </span>
      ),
      privacyHere: 'Please see our ',
      step1: 'Step 1: Add your Audiogram',
      step2: 'Step 2: Add a Sound Track',
      step3: 'Stap 3: Connect the two',
      dontForgetToSave: "Don't forget to",
      swipeToSoundTracks: 'or swipe to go to Sound Tracks.',
      swipeToAudiograms: 'or swipe to go to Audiograms.',
      addTrack: 'to add a track.',
      audiogramIs:
        'An audiogram is a graph that indicates the amount of hearing loss and is ' +
        'used to filter the soundtrack. This gives an indication of how it will sound. ' +
        'Ask your hearing professionals for a copy of your audiogram. ' +
        'Or for now use one of the examples.',
      createAudiogram: 'to create an audiogram.',
      installAsApp: 'Install as an app',
      installTheApp: 'To install as an app, please click here',
      has2Advantages: 'has 2 advantages.',
      twoAdvantages: (
        <span>
          1 - The app works even if there is no internet
          <br /> 2 - Updates are faster and easier
        </span>
      ),
      notesTitle: 'Notes on Audiograms',
      audiogramsExport: 'Audiograms can be sent using',
      audiogramsSend: 'Send the audiogram direct or send a generated code to be used on another device',
      audiogramsImport: 'Receive an audiogram or a code to add an audiogram using',
      supportAt: 'For support please email me at mark@filius.cc.',
      youAre: 'You are',
      getTips: 'to get tips and some help',
    },
    audiograms: {
      unsavedTitle: 'You have unsaved changes',
      unsavedDescription: 'OK to go back without save?',
      addedToList: '%{n} audiogram(s) added to the list',
      invalidCode: 'A received audiogram appears to be an invalid code',
      error: 'Unknown error while receiving an audiogram',
      giveTitle: 'Please give the audiogram a title before saving',
      foundWait: 'Found. Please wait a moment...',
      receivedInvalid: "It looks like the received audiogram isn't valid",
      noIdentity: "Didn't find anything for that identity and password",
      edit: 'Edit Audiogram',
      add: 'Add Audiogram',
      receiveDirectly: 'Receive an audiogram directly',
      haveALongCode: 'You should have received a rather long code. Paste that code here',
      iHaveCode: 'I have a code for an audiogram',
      anAudiogram: 'an audiogram',
      identityLonger: 'The identity must be longer.',
      passwordLonger: 'The password must be longer.',
      notificationReceived:
        'An audiogram has been sent to you. To receive it, go to Audiograms and click on the + icon. It is available until %{date}.',
      sentMessage: 'Audiogram is sent to %{user}. It is available until %{date}',
      sendAudiogram: 'Send an audiogram',
      sendDirectly: 'Send an audiogram directly',
      sendTo: 'Send %{name} to:',
      identityReceiver: 'The identity of the receiver',
      generateCode: 'Generate a code to send by email',
      theCodeFor: 'The code for %{name}:',
      deleteTitle: 'Delete?',
      deleteDescription: 'Delete audiogram %{name}?',
      resetTitle: 'Reset?',
      resetDescription: 'Reset example audiogram "%{name}" to the original audiogram values?',
      audiogramIsReset: 'Audiogram is reset to original values',
      exampleLightName: 'Example Audiogram - light loss',
      exampleModerateName: 'Example Audiogram - moderate loss',
      exampleSevereName: 'Example Audiogram - severe loss',
      examplePresbyacusisM50: 'Average loss for men at 50 years old',
      examplePresbyacusisM70: 'Average loss for men at 70 years old',
      examplePresbyacusisF50: 'Average loss for women at 50 years old',
      examplePresbyacusisF70: 'Average loss for women at 70 years old',
    },
    soundTracks: {
      soundTrack: 'Sound Track',
      unsavedTitle: 'You have a recording',
      unsavedDescription: 'OK to go back without save?',
      somethingWrong: "Something went wrong. The soundtrack wasn't found",
      notAudioFile: "Fyi: this doesn't seem to be an audio file - it probably won't play correctly",
      maxSize: 'Audio file may not be larger than %{n} Mb',
      giveTitle: 'Please provide a title',
      chooseFile: 'Choose a file',
      makeRecording: 'Make a recording',
      recordingMaxSeconds: 'A recording has started. Remaining seconds: ',
      notIos: ' (not available on iOS or Safari)',
      noSoundTrack: 'No soundtrack file given to encode',
      frequency: 'Frequency',
      frequencyHz: 'Frequency (Hz)',
      soundDb: 'Sound (dB)',
      audiogram: 'Audiogram',
      selectAudiogram: 'Select Audiogram',
      selectAnAudiogram: 'Select an Audiogram',
      withAudiogram: ' (with audiogram)',
      withoutAudiogram: ' (normal)',
      noAudiogramSelected: (
        <span style={{ display: 'flex', width: '200%' }}>
          Choose an audiogram. Click on
          <Icon style={{ display: 'flex', fontSize: 20 }}>more_vert</Icon>
        </span>
      ),
      audiogramIsOff: 'Playing normal',
      audiogramIsOn: 'With audiogram',
      deleteTitle: 'Delete?',
      deleteDescription: 'Delete sound track %{name}?',
      tracksUpdated: '%{n} sound tracks updated with the audiogram',
      firstAddAudiogram: 'First add an audiogram. Click on the 3 dots to select an audiogram',
      example: 'Example',
      exampleBabyLaugh: 'Baby laugh',
      exampleForestBird: 'Forest bird',
      exampleJingleBells: 'Jingle Bells',
      examplePingPing: 'Ping',
      getFromLibrary: 'Add from the library',
      isSoundEffect: 'Sound Effect',
      hasSinging: 'Singing',
      libraryIsEmpty: 'The library is unfortunately empty',
    },
    admin: {
      maxSize: 'Sound Tracks rather less than %{n} Mb - but we will upload anyway...',
    },
  },
  nl: {
    general: {
      appName: 'Hearing Loss Sounds Like',
      appNameSubtitle: 'Hoe Klinkt Gehoorverlies',
      use: 'Gebruik',
      and: 'en',
      dontForget: 'Vergeet niet te',
      installing: 'Installeren',
      inYourBrowser: 'in de browser',
      onYourPhone: 'op je telefoon',
      onYourTablet: 'op je tablet',
      add: 'Toevoegen',
      edit: 'Wijzig',
      build: 'Maak',
      send: 'Verzend',
      save: 'Opslaan',
      delete: 'Verwijder',
      reset: 'Reset',
      receive: 'Ontvang',
      frequency: 'Frequentie',
      loss: 'Verlies',
      title: 'Titel',
      wantToUpdate: '"Hearing Loss Sounds Like" moet updaten. Graag de app helemaal afsluiten en opnieuw starten',
      updateReminder: 'Vergeet niet om te updaten. Graag de app helemaal afsluiten en opnieuw starten',
    },
    heading: {
      soundTracks: 'Geluidsfragmenten',
      audiograms: 'Audiogrammen',
      help: 'Help',
      privacy: 'Privacy Policy',
      youAre: 'Jij bent %{name}',
      identityLonger: 'De identiteit moet langer zijn.',
      identityToReceive: 'Om een audiogram te ontvangen, moeten we je kunnen identificeren. ',
    },
    identity: {
      yourIdentity: 'Jouw identiteit, bijv. je emailadres',
      password: 'Het wachtwoord voor het audiogram',
    },
    toast: {
      usePlus: 'Gebruik het + teken rechtsonder de lijst',
      useMenu: 'Gebruik het menu icoontje achter mij',
      willBeInstalled: 'The Hearing Loss Sounds Like app wordt geinstalleerd',
      chooseLanguage: 'Graag de taal kiezen',
    },
    tips: {
      use: 'Gebruik',
      toSelectAudiogram: 'om een audiogram te kiezen',
      createAudiogramUsing: 'Maak een audiogram via het menu',
      buttonOrSwipe: (
        <span>
          of <i>swipe</i> naar Audiogrammen.
        </span>
      ),
      addSoundtrack: 'om op te nemen, muziek uit de bibliotheek te halen of zelf toe te voegen',
      nextToSoundTrack: 'naast een geluidsfragment.',
      playNormal: 'om ongefiltered af te spelen (zonder audiogram)',
      playWith: 'om gefiltered af te spelen (met audiogram)',
      toAddAudiogram: 'om een audiogram toe te voegen',
      sendAudiogramToMe: 'Heeft iemand jouw audiogram al ingevoerd? Dan kunnen ze het naar jou verzenden',
      moreOptions: 'voor meer opties',
      clickToChange: 'Gebruik de regelaars om het audiogram aan te passen. Niet te snel, aub.',
      addPointsDescription:
        'Een audiogram heeft 2 assen. De verticale as wordt gemeten in decibel (dB), dat is de volume. ' +
        'Bovenaan is 0 dB wat de meeste mensen niet kunnen horen. Onderaan is 120 dB dat zeer, zeer hard is. ' +
        'De horizontale as wordt gemeten in Hertz en kiloHertz, dat is de frequentie of toonhoogte. ' +
        'Aan de rechterkant zijn de hoger frequenties (dus hogere toonhoogte).',
      addPointsInstruction:
        'Zoek de punten in jouw audiogram. Klik in het audiogram op de lijn of op de punten. ' +
        'Of, gebruik de schuifbalk om de waarden op te zoeken. ' +
        'Bijvoorbeeld, als je audiogram bij 1000 Hertz (of 1 kiloHertz) een punt toont bij 30 decibel, ' +
        'verschuif de bovenste schuifbalk naar 1 kHz en de onderste naar 30 dB. ',
      senderGivesDetails: 'De zender moet deze gegevens verstrekken om een audiogram te ontvangen',
      clickReceive: 'Dan rechtsonder op "Ontvangen" klikken',
      chooseIdentity: 'Kies een identiteit voor de ontvanger en een wachtwoord',
      giveDetails: 'Geef deze details aan de ontvanger',
      availablePeriod: 'Het blijft beschikbaar voor de komende week',
      exportAudiogram: 'Gebruik deze code om een audiogram toe te voegen',
      sendCode: 'Verzend deze code via email of sms',
      copiedClipboard: 'De code is al geknipt (naar het klembord)!',
      playFilteredMeans:
        'Wat betekent dit? Wat je nu hoort is slechts een indicatie van wat de slechthorende hoort. ' +
        'Let op: achtergrond lawaai heeft een enorme invloed - een veel groter invloed dan voor jezelf. ' +
        'En ook: afhankelijk van het verlies, is het mogelijk dat 1 oor beter hoort dan het andere',
    },
    help: {
      languageTitle: 'Taal keuze:',
      privacyTitle: 'Privacy Policy (English only. Alleen in het Engels)',
      safariTitle: 'Gebruik je Safari?',
      safariDescription:
        'Het lijkt erop dat je de Safari browser gebruikt. ' +
        'Helaas hebben sommige Safari versies en bijhorende instellingen moeite met audio toepassingen. ' +
        'Je kunt problemen ervaren zoals geluidsfragmenten die zomaar ophouden, helemaal geen geluid of "IDB" problemen. ' +
        'In dat geval raden we aan om een andere browser te gebruiken, bij voorkeur Chrome of Edge.',
      installTitle: 'Installeer de app op een mobiele telefoon of tablet',
      installDescription:
        'Het lijkt erop dat je op een mobiele apparaat werkt. ' +
        'Hearing Loss Sounds Like kan worden geinstalleerd als een app. ' +
        'Dit heeft 2 voordelen: 1) het werkt zonder internet, ' +
        'en 2) makkelijker updates. ' +
        'Kijk bij de Help om te installeren als een app.',
      whoAmI: 'Wie ben ik?',
      whoAmITitle: 'Wie ben ik en waarom deze app?',
      whoAmIDescription:
        'Mijn dochter heeft een behoorlijk gehoorverlies waarbij praktisch gesproken slechts 1 oor meedoet ' +
        'en dan ook nog nauwlijks. ' +
        'Het is erg moeilijk voor iedereen - ook mijzelf - om een goed idee te krijgen wat dat dagelijks betekent. ' +
        'Deze app probeert een idee te geven wat het betekent om slechthorend te zijn. ' +
        'Graag wel erbij bedenken dat de werkelijke gevolgen veel verder gaan dan wat deze app kan laten horen. ' +
        'Bedankt voor het proberen! Mark.',
      thisIsHelp: 'Dit is de Help pagina voor',
      thisIsPrivacy: 'Dit is de Privacy Policy pagina voor',
      listenToLike: (
        <span>
          Luister naar een geluidsfragment zoals een slechthorende het hoort. Bedenk erbij dat het onmogelijk is
          nauwkeurig het mensenlijke gehoor na te bootsen.
          <br />
          <br />
          Het mensenlijke oor en brein zijn bijzonder knap - alle huidige hoortoestellen en technologieën zijn een
          redelijk alternatief, maar ze zijn nooit een volledige compensatie voor het verlies.
          <br />
          <br />
          Deze app bootst geleidingsverlies van het oor na en kan daarmee slechts een idee geven van een wereld met
          uitdagingen voor slechthorenden.
        </span>
      ),
      privacyPolicy: (
        <span>
          This privacy policy governs the use of the software application “Hearing Loss Sounds Like” (referred as the
          “Application”).
          <br />
          <br />
          The Application is a Web and Android application intended to give users an indication of what a hearing
          impaired person hears.
          <br />
          <br />
          The Application does not require any user registration nor any personal information.
          <br />
          <br />
          In the specific case only when a user wishes to send an audiogram to another user, the user is requested to
          provide a random but unique identifier. An example is given of an email address. The Application only stores
          the identifier locally on the device. A one-way encrypted version of the identifer is stored remotely for
          maximum 1 week to allow sharing/sending of the audiograam. The Application does not collect any other type of
          personal information. The user's identifier can be deleted by the user at any time.
          <br />
          <br />
          For any questions regarding privacy. please contact the developer via email at: mark@filius.cc
          <br />
          <br />
          Last updated: 8 Jan 2024
          <br />
        </span>
      ),
      privacyHere: 'Zie ook onze ',
      step1: 'Stap 1: Voeg jouw audiogram toe',
      step2: 'Stap 2: Voeg een geluidsfragment toe',
      step3: 'Stap 3: Verbind ze samen',
      dontForgetToSave: 'Vergeet niet op te slaan',
      swipeToSoundTracks: (
        <span>
          of <i>swipe</i> om naar Geluidsfragmenten toe te gaan.
        </span>
      ),
      swipeToAudiograms: (
        <span>
          of <i>swipe</i> om naar Audiogrammen te gaan.
        </span>
      ),
      addTrack: 'om een geluidsfragment toe te voegen.',
      audiogramIs:
        'Een audiogram is een grafiek dat het gehoorverlies weergeeft. Het wordt als filter gebruikt, ' +
        'dat geeft een indicatie hoe het geluidsfragment zal klinken. ' +
        'Vraag jouw audiogram op bij de audicien, audioloog of KNO arts. ' +
        'Of gebruik tijdelijk een van de voorbeelden.',
      createAudiogram: 'om een audiogram te maken.',
      installAsApp: 'Installeer zoals een app',
      installTheApp: 'Om te installeren, klik hier',
      has2Advantages: 'heeft 2 voordelen.',
      twoAdvantages: (
        <span>
          1 - De app werkt zelfs als er geen internet is
          <br /> 2 - Updates zijn sneller en makkelijker
        </span>
      ),
      notesTitle: 'Betreffende audiogrammen',
      audiogramsExport: 'Verzend audiogrammen met',
      audiogramsSend: 'Verzend een audiogram direct of verzend een code naar een ander apparaat',
      audiogramsImport: 'Ontvang een audiogram of een code met',
      youAre: 'Je bent',
      supportAt: 'Voor help, email naar mark@filius.cc.',
      getTips: 'voor tips en wat hulp',
    },
    audiograms: {
      unsavedTitle: 'Er zijn aanpassingen',
      unsavedDescription: 'OK om niet op te slaan?',
      addedToList: '%{n} audiogrammen toegevoegd',
      invalidCode: 'Een ontvangen audiogram lijkt een ongeldige code te zijn',
      error: 'Onbekende foutmelding gedurende audiogram ontvangen',
      giveTitle: 'Geef de audiogram een titel',
      foundWait: 'Gevonden. Momentje aub...',
      receivedInvalid: 'Het lijkt erop dat de ontvangen audiogram niet geldig is',
      noIdentity: 'Niks gevonden voor identiteit en wachtwoord',
      edit: 'Aanpassen audiogram',
      add: 'Toevoegen audiogram',
      receiveDirectly: 'Ontvang een audiogram direct',
      haveALongCode: 'Je moet een nogal lange code hebben ontvangen. Plak die code hier',
      iHaveCode: 'Ik heb een code voor audiogram',
      anAudiogram: 'een audiogram',
      identityLonger: 'De identiteit moet langer zijn.',
      passwordLonger: 'Het wachtwoord moet langer zijn.',
      notificationReceived:
        'Een audiogram is verzonden naar jou. Te ontvangen, ga naar Audiogrammen en klik op + icoon. Het is beschikbaar tot %{date}.',
      sentMessage: 'Audiogram is verzonden naar %{user}. Het is beschikbaar tot %{date}',
      sendAudiogram: 'Verzend een audiogram',
      sendDirectly: 'Verzend een audiogram direct',
      sendTo: 'Verzend %{name} naar:',
      identityReceiver: 'De identiteit van de ontvanger',
      generateCode: 'Verzend een code via email',
      theCodeFor: 'De code voor %{name}:',
      deleteTitle: 'Verwijder?',
      deleteDescription: 'Verwijder audiogram %{name}?',
      resetTitle: 'Reset?',
      resetDescription: 'Reset voorbeeld audiogram "%{name}" naar de oorspronkelijke waarden?',
      audiogramIsReset: 'Audiogram is reset naar de oorspronkelijke waarden',
      exampleLightName: 'Voorbeeld audiogram 1 - licht verlies',
      exampleModerateName: 'Voorbeeld audiogram 2 - matig verlies',
      exampleSevereName: 'Voorbeeld audiogram 3 - ernstig verlies',
      examplePresbyacusisM50: 'Gemiddeld verlies voor mannen 50 jaar oud',
      examplePresbyacusisM70: 'Gemiddeld verlies voor mannen 70 jaar oud',
      examplePresbyacusisF50: 'Gemiddeld verlies voor vrouwen 50 jaar oud',
      examplePresbyacusisF70: 'Gemiddeld verlies voor vrouwen 70 jaar oud',
    },
    soundTracks: {
      soundTrack: 'Geluidsfragment',
      unsavedTitle: 'Er is een opname',
      unsavedDescription: 'OK om niet op te slaan?',
      somethingWrong: 'Er gaat iets mis. Het geluidsfragment is niet gevonden',
      notAudioFile: "Het lijkt erop dat dit niet een geluidsfragment is - wellicht speelt 't niet goed af",
      maxSize: 'Geluidsfragmenten mogen niet groter zijn dan %{n} Mb',
      nothing: 'Nothing to save',
      giveTitle: 'Geef het fragment een titel',
      chooseFile: 'Kies een bestand',
      makeRecording: 'Maak een opname',
      recordingMaxSeconds: 'Een opname is begonnen. Seconden nog: ',
      notIos: ' (niet beschikbaar op iOS of Safari)',
      noSoundTrack: 'Geen fragment ontvangen om te coderen',
      frequency: 'Frequentie',
      frequencyHz: 'Frequentie (Hz)',
      soundDb: 'Volume (dB)',
      audiogram: 'Audiogram',
      selectAudiogram: 'Kies audiogram',
      selectAnAudiogram: 'Kies een audiogram',
      withAudiogram: ' (gefiltered met audiogram)',
      withoutAudiogram: ' (gewoon, dus ongefiltered)',
      noAudiogramSelected: (
        <span style={{ display: 'flex', width: '200%' }}>
          Kies een audiogram. Klik op
          <Icon style={{ display: 'flex', fontSize: 20 }}>more_vert</Icon>
        </span>
      ),
      audiogramIsOff: 'Speelt gewoon',
      audiogramIsOn: 'Met audiogram',
      deleteTitle: 'Verwijder?',
      deleteDescription: 'Verwijder fragment %{name}?',
      tracksUpdated: '%{n} fragmenten bijgewerkt',
      firstAddAudiogram: 'Voeg eerst een audiogram toe. Klik op de 3 puntjes',
      example: 'Voorbeeld',
      exampleBabyLaugh: 'Baby lach',
      exampleForestBird: 'Vogels in het bos',
      exampleJingleBells: 'Jingle Bells',
      examplePingPing: 'Ping',
      getFromLibrary: 'Voeg toe van de bibliotheek',
      isSoundEffect: 'Geluidseffect',
      hasSinging: 'Zang',
      libraryIsEmpty: 'De bibliotheek is helaas leeg',
    },
    admin: {
      maxSize: 'Geluidsfragmenten liever niet groter dan %{n} Mb - maar we gaan toch uploaden...',
    },
  },
}

export default allLanguages
