
const handleError = (subject: string, error: any) => {
    console.error('Unexpected error - see following line(s)')
    console.error(subject, error)
    alert(`Something has gone wrong: ${subject}, ${error && error.message}`)
}

export {
    handleError,
}

