// Third party imports
import React, {useState} from 'react'
import ReactGA from 'react-ga'

// Our imports
import SoundTracksList from './sound-tracks-list'
import SoundTrackEdit from "./sound-track-edit"
import {ISoundTrack, ISoundTracksProps} from '../types/sound-tracks-interfaces'

ReactGA.event({
  category: 'Sound Tracks',
  action: 'Load',
});

const SoundTracks = (props: ISoundTracksProps) => {
  const [doExport, setDoExport] = useState(false)
  const [track, setTrack] = useState()

  const editTrack = (track: ISoundTrack | string) => {
    setTrack(track)
  }

  const sendTrack = (track: ISoundTrack) => {
    setTrack(track)
    setDoExport(true)
  }

  if (doExport && track) {
    return (
      <SoundTrackEdit track={track} onHeaderClick={() => setTrack('')}/>
    )
  } else if (track) {
    return (
      <SoundTrackEdit track={track} onHeaderClick={() => setTrack('')}/>
    )
  } else {
    return (
      <SoundTracksList editTrack={editTrack} sendTrack={sendTrack} nav={props.nav}/>
    )
  }
}

export default SoundTracks
