// Third party imports
import React, { useState, useEffect } from 'react'
import {
  Fab,
  TextField,
  ExpansionPanel,
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  Icon,
  List,
  ListItem,
  ListItemText,
  Divider,
} from '@material-ui/core'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import MusicNoteIcon from '@material-ui/icons/MusicNote'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CircularProgress from '@material-ui/core/CircularProgress'
import { isSafari, isIOS, isMobileOnly } from 'react-device-detect'
import { useConfirm } from 'material-ui-confirm'
import { translate as t } from 'react-i18nify'
import Countdown from 'react-countdown'

// Our imports
import Header from '../common/header'
import Toast from '../common/toast'
import { logCentral } from '../common/logger'
import { handleError } from '../common/handle-error'
import * as model from './sound-track-model'
import useStyles from '../styles/sound-tracks-styles'
import { ILibraryTrack, ISoundTrackEditProps } from '../types/sound-tracks-interfaces'
import { recorder } from './sound-track-record'
import libraryService from '../common/library-service'

const MAX_FILE_SIZE = 10
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE * 1024 * 1024
const MAX_RECORD_SEC = 30

const SoundTrackEdit = (props: ISoundTrackEditProps) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [id, setId] = useState('')
  const [title, setTitle] = useState('')
  const [file, setFile] = useState()
  const [isRecording, setIsRecording] = useState(false)
  const [expanded, setExpanded] = useState('')
  const [recordingBlob, setRecordingBlob] = useState()
  const [renderPlayRecording, setRenderPlayRecording] = useState(false)
  const [isSoundTrackUpdated, setIsSoundTrackUpdated] = useState(false)
  const [toast, setToast] = useState('')
  const [tracksLibrary, setTracksLibrary] = useState<ILibraryTrack[]>([])
  const [displaySave, setDisplaySave] = useState(false)
  const [displayProgress, setDisplayProgress] = useState(false)
  const [retrieving, setRetrieving] = useState('')
  const confirm = useConfirm()

  // Effect to get the library on page load
  useEffect(() => {
    libraryService
      .getContents()
      .then(libraryContents => {
        setTracksLibrary(libraryContents)
      })
      .catch(error => {
        handleError('Error while getting library contents', error)
      })
  }, [])

  // Effect set details when receive a track
  useEffect(() => {
    if (typeof props.track === 'object') {
      setEditMode(true)
      setId(props.track.id || '')
      setTitle(props.track.name || '')
    }
  }, [props.track])

  const goBack = (message: string | boolean = '') => {
    if (message === false || !isSoundTrackUpdated) {
      props.onHeaderClick()
      return
    }

    confirm({
      title: t('soundTracks.unsavedTitle'),
      description: t('soundTracks.unsavedDescription'),
    }).then(() => {
      props.onHeaderClick()
    })
  }

  const handleFindFile = async (event: any) => {
    const file = event.target.files[0]

    if (!file) {
      setToast(t('soundTracks.somethingWrong'))
      return false
    }

    if (file.type.slice(0, 5) !== 'audio') {
      setToast(t('soundTracks.notAudioFile'))
    }

    if (file.size > MAX_FILE_SIZE_BYTES) {
      setToast(t('soundTracks.maxSize', { n: MAX_FILE_SIZE }))
      setFile(null)
      return false
    }

    setFile(file)

    if (title === '') {
      setTitle(file.name)
    }
  }

  const handleSave = () => {
    const toBeSaved = expanded === 'file' ? file : recordingBlob

    if (typeof props.track !== 'object' && !toBeSaved) {
      // No new obect to be saved
      setToast(t('soundTracks.nothing'))
      return
    }

    if (!title) {
      setToast(t('soundTracks.giveTitle'))
      return
    }

    let saveFile
    let audiogramId = undefined
    if (typeof props.track !== 'object') {
      // New file
      saveFile = toBeSaved
      logEvent('Add new soundtrack', title)
    } else {
      // Existing file update
      saveFile = props.track.file
      audiogramId = props.track.audiogramId
    }

    model
      .save({
        id: id,
        name: title,
        file: saveFile,
        audiogramId: audiogramId,
      })
      .then(() => {
        goBack(false)
      })
      .catch(error => {
        handleError('Error while saving', error)
      })
  }

  const makeRecording = async () => {
    logEvent('Start a recording', title)
    setIsRecording(true)
    setRenderPlayRecording(false)
    setIsSoundTrackUpdated(true)

    const blob = await recorder().record(MAX_RECORD_SEC)

    setRecordingBlob(blob)
    setRenderPlayRecording(true)
    setIsRecording(false)
  }

  const stopRecording = () => {
    recorder().stop()
  }

  // @ts-ignore - types of the parameters
  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) return <span>---</span>
    return <span className={classes.secondaryText}>{seconds}</span>
  }

  const countdown = () => {
    return (
      <div className={classes.recordContainer}>
        <span className={classes.secondaryText}>{t('soundTracks.recordingMaxSeconds')}</span>
        <Countdown date={Date.now() + MAX_RECORD_SEC * 1000} renderer={countdownRenderer} />
      </div>
    )
  }

  const playRecording = () => {
    if (!recordingBlob) return null

    const audioURL = URL.createObjectURL(recordingBlob)
    return (
      <audio className={classes.playBtn} controls>
        <source src={audioURL} type={'audio/ogg; codecs=opus'} />
      </audio>
    )
  }

  const playFile = (file: any) => {
    if (!file) return null

    const fileSource = URL.createObjectURL(file)
    return (
      <audio controls>
        <source src={fileSource} type={file.type} />
        This device or browser unfortunately doesn't support playing audio
      </audio>
    )
  }

  const libraryLine2 = (track: ILibraryTrack) => {
    /*
    In prod this doesnt work well. The filer always returns undefined. It seems that item is a dom element???
    const nrOfThese = tracksLibrary.filter(item => (item.title + item.artist) === (track.title + track.artist))
    const displayType = nrOfThese.length > 1
    */
    let genres = track.isSoundEffect ? track.genres.concat(['Sound effect']) : track.genres
    genres = genres.filter(genre => genre !== '')
    return (
      <span className={classes.libraryLine2}>
        {track.hasSinging ? <Icon fontSize={'small'}>mic</Icon> : null}
        {track.isSoundEffect ? <MusicNoteIcon fontSize={'small'} /> : null}
        {genres.join(', ')}
        {/*{displayType ? <span>{track.fileType}</span> : null}*/}
      </span>
    )
  }

  const handleSource = (source: string) => (event: any, isExpanded: boolean) => {
    /* Allow record in safari if the user really wants to (but they have been warned)
    if ((isSafari || isIOS) && source === 'record') {
      return
    }
    */
    setDisplaySave(!source || source === 'library' ? false : true)
    setExpanded(isExpanded ? source : '')
    setTitle('')
  }

  const handleLibraryDownload = (track: ILibraryTrack) => {
    setDisplayProgress(true)
    setRetrieving(track.fileName + track.fileType)
    logEvent('Get from library', track.title)

    libraryService
      .retrieve(track)
      .then(retrievedTrack => {
        const trackName = `${track.title}${track.artist ? ', ' + track.artist : ''}`
        model
          .save({
            name: trackName,
            file: retrievedTrack,
          })
          .then(() => {
            goBack(false)
          })
          .catch(error => {
            handleError('Error while saving a retrieving track', error)
          })
          .finally(() => {
            setDisplayProgress(false)
          })
      })
      .catch(error => {
        handleError('Error while retrieving track', error)
      })
      .finally(() => {
        setRetrieving('')
        setDisplayProgress(false)
      })
  }

  const logEvent = (action: string, label?: string) => {
    logCentral('Sound Tracks', action, label)
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootLargeScreen}>
        <Header
          classes={classes}
          title={`${editMode ? t('general.edit') : t('general.add')} ${t('soundTracks.soundTrack')}`}
          menuOrBack={'back'}
          onButtonClick={goBack}
        />

        {/* The sound track title */}
        <div className={classes.titleContainer}>
          <TextField
            id="soundTrackTitle"
            label={t('general.title')}
            placeholder={t('general.title')}
            className={classes.textField}
            margin="normal"
            onChange={event => setTitle(event.target.value)}
            value={title}
          />
        </div>

        {/* Select a file */}
        {editMode ? null : (
          <ExpansionPanel
            className={`${classes.panelRoot} ${(expanded === 'library' || expanded === 'record') && classes.hidden}`}
            expanded={expanded === 'file'}
            onChange={handleSource('file')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('soundTracks.chooseFile')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <TextField
                id="soundTrackFile"
                className={classes.textField}
                margin="normal"
                type="file"
                onChange={handleFindFile}
              />

              {/* Play button */}
              {isMobileOnly ? null : <div className={classes.playBtnContainer}>{playFile(file)}</div>}
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {/* Get a sound track from library */}
        {editMode ? null : (
          <ExpansionPanel
            className={`${classes.panelRoot} ${(expanded === 'file' || expanded === 'record') && classes.hidden}`}
            expanded={expanded === 'library'}
            onChange={handleSource('library')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('soundTracks.getFromLibrary')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.listContainer}>
                <div className={classes.legend}>
                  <Icon color={'disabled'}>mic</Icon> = {t('soundTracks.hasSinging')}
                  <MusicNoteIcon color={'disabled'} /> = {t('soundTracks.isSoundEffect')}
                </div>
                <List className={classes.listRoot}>
                  {tracksLibrary.length === 0 ? <p>{t('soundTracks.libraryIsEmpty')}</p> : null}
                  {tracksLibrary.map(track => {
                    return (
                      <div key={track.fileName + track.fileType}>
                        {!track.fileName ? null : (
                          <ListItem className={classes.libraryListItem}>
                            <ListItemText
                              primary={`${track.title}${track.artist ? ', ' + track.artist : ''}`}
                              secondary={libraryLine2(track)}
                            />
                            {displayProgress && retrieving === track.fileName + track.fileType && (
                              <CircularProgress color="primary" size={20} className={classes.libraryProgress} />
                            )}
                            <AddCircleIcon color={'secondary'} onClick={() => handleLibraryDownload(track)} />
                          </ListItem>
                        )}
                        <Divider component="li" />
                      </div>
                    )
                  })}
                </List>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {/* Record a sound track */}
        {editMode ? null : (
          <ExpansionPanel
            className={`${classes.panelRoot} ${(expanded === 'library' || expanded === 'file') && classes.hidden}`}
            expanded={expanded === 'record'}
            onChange={handleSource('record')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={isSafari || isIOS ? classes.secondaryText : ''}>
                {t('soundTracks.makeRecording')}
                {isSafari || isIOS ? t('soundTracks.notIos') : ''}
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Fab
                variant="extended"
                color={isRecording ? 'default' : 'secondary'}
                disabled={isRecording}
                aria-label="Start Recording"
                className={classes.fabRecord}
                onClick={makeRecording}
              >
                <Icon>play_arrow</Icon>
              </Fab>
              <Fab
                variant="extended"
                color={!isRecording ? 'default' : 'secondary'}
                disabled={!isRecording}
                aria-label="Stop Recording"
                className={classes.fabRecord}
                onClick={stopRecording}
              >
                <Icon>stop</Icon>
              </Fab>

              {/* Play button */}
              <div className={classes.playBtnContainer}>
                {isRecording ? countdown() : null}
                {renderPlayRecording ? playRecording() : null}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {/* Save button */}
        {!displaySave ? null : (
          <div className={classes.fabContainer}>
            <Fab
              variant="extended"
              color="primary"
              aria-label="Save"
              className={classes.fab}
              disabled={isRecording}
              onClick={handleSave}
            >
              {t('general.save')}
            </Fab>
          </div>
        )}

        <Toast message={toast} />
      </div>
    </div>
  )
}

export default SoundTrackEdit
