import axios from 'axios'
import ReactS3Client from 'react-aws-s3-typescript'

import constants from './constants'
import {ILibraryTrack} from "../types/sound-tracks-interfaces"

const generateS3UrlParts = (fileId: string, folderOrFull: 'folder' | 'full'): string => {
  const s3Root = `https://${constants.S3_BUCKET_NAME}.s3-${constants.S3_REGION}.amazonaws.com/`
  const folderPath = `${process.env.REACT_APP_ENVIRONMENT}/${constants.LIBRARY_FOLDER}`

  switch (folderOrFull) {
    case 'folder':
      return folderPath

    case 'full':
      let getThisFile = fileId || `${constants.LIBRARY_FILE_NAME}.${constants.LIBRARY_FILE_TYPE}`
      return `${s3Root}${folderPath}/${getThisFile}`
  }

  return ''
}

// Reader to actually read the file
const streamFile = async (_path: string, options = {}) => {
  try {
    const data = await axios.get(_path, options)
    return data.data
  } catch (error) {
    // There are no notifications
    return ''
  }
}

const libraryService = {
  getContents: async (): Promise<ILibraryTrack[]> => {
    // Build the fileName on s3 and retrieve the file for the contentsList
    const fullPath = generateS3UrlParts('', 'full')
    const contentsList = await streamFile(fullPath) || []

    // Sort the contents on title
    contentsList.sort((a: ILibraryTrack, b: ILibraryTrack) => {
      return a.title.localeCompare(b.title)
    })

    return Promise.resolve(contentsList || [])
  },

  saveContents: async (list: ILibraryTrack[]): Promise<any> => {
    // Create a file and upload to S3
    const fileContent = list
    const folderPath = generateS3UrlParts('', 'folder')
    const fileName = constants.LIBRARY_FILE_NAME
    const s3Config = {
      bucketName: constants.S3_BUCKET_NAME,
      region: constants.S3_REGION,
      accessKeyId: constants.S3_ACCESS_KEY,
      secretAccessKey: constants.S3_SECRET,
    }

    try {
      const s3 = new ReactS3Client({
        ...s3Config,
        dirName: folderPath,
      });
      const fileTypeMime = `application/json`
      const file = new File([JSON.stringify(fileContent)], fileName, {type: fileTypeMime})
      console.log('Uploading lib contents file', fileName, file, folderPath)
      console.log('Libary contents:', fileContent)
      await s3.uploadFile(file, fileName);
      return Promise.resolve('')

    } catch (error) {
      return Promise.reject(error.toString())
    }
  },

  retrieve: async (track: ILibraryTrack): Promise<File> => {
    // Build the fileName on s3 and retrieve the file contents
    const fullPath = generateS3UrlParts(track.fileName + track.fileType, 'full')
    try {
      const libraryTrack = await streamFile(fullPath, {
        responseType: 'arraybuffer',
      })
      if (!libraryTrack) throw new Error('Didnt receive a file');
      const libraryFile = new File([libraryTrack], track.fileName + track.fileType)
      return Promise.resolve(libraryFile)
    } catch (error) {
      return Promise.reject(`Error retrieving library track: ${error.toString()}`)
    }
  },

  addTrack: async (file: File, fileMeta: ILibraryTrack): Promise<ILibraryTrack[]> => {
    // Upload to S3
    const s3Config = {
      bucketName: constants.S3_BUCKET_NAME,
      region: constants.S3_REGION,
      accessKeyId: constants.S3_ACCESS_KEY,
      secretAccessKey: constants.S3_SECRET,
    }
    const folderPath = generateS3UrlParts('', 'folder')
    try {
      const s3 = new ReactS3Client({
        ...s3Config,
        dirName: folderPath,
      });

      // Strange behaviour of the lib: we need to reset the file type in the meta info
      const fileType = file.type.split('/').pop() || ''
      fileMeta.fileType = '.' + fileType
      await s3.uploadFile(file, fileMeta.fileName)

    } catch (error) {
      return Promise.reject(error.toString())
    }

    // Update contents file - overwrite any duplicate
    let libraryContents: ILibraryTrack[] = await libraryService.getContents()
    const toAddFile = fileMeta.fileName + fileMeta.fileType
    libraryContents = libraryContents.filter(item => item.fileName + item.fileType !== toAddFile)
    libraryContents.push(fileMeta)

    libraryService.saveContents(libraryContents)

    return Promise.resolve(libraryContents)
  },

  deleteTrack: async (track: ILibraryTrack): Promise<ILibraryTrack[]> => {
    // Delete the file
    const filePath = generateS3UrlParts(track.fileName + track.fileType, 'full')
    const s3Config = {
      bucketName: constants.S3_BUCKET_NAME,
      region: constants.S3_REGION,
      accessKeyId: constants.S3_ACCESS_KEY,
      secretAccessKey: constants.S3_SECRET,
    }
    try {
      const s3 = new ReactS3Client({
        ...s3Config,
      });
      await s3.deleteFile(filePath)
    } catch (error) {
      return Promise.reject(error.toString())
    }

    // Update the contents list
    const libraryContents = await libraryService.getContents()
    const trackId = track.fileName + track.fileType
    const updatedContents = libraryContents.filter(item => (item.fileName + item.fileType) !== trackId)

    // Save the updated contents list
    libraryService.saveContents(updatedContents)

    return Promise.resolve(updatedContents)
  }
}

export default libraryService

