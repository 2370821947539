import localforage from 'localforage'

export const localforageConfig = () => localforage.config({
    name: 'hearinglosssoundslike',
    storeName: 'store',

    // Back to default: IndexedDB
    // driver: localforage.LOCALSTORAGE,
})

