// Third party imports
import React, {useState} from 'react'
import ReactGA from 'react-ga'

// Our imports
import Toast from '../common/toast'
import AudiogramsList from './audiograms-list'
import AudiogramEdit from './audiogram-edit'
import AudiogramExport from './audiogram-export';
import {IAudiogram, IAudiogramsProps} from '../types/audiograms-interfaces'

ReactGA.event({
    category: 'Audiograms',
    action: 'Load',
});

const Audiograms = (props: IAudiogramsProps) => {

    const [audiogram, setAudiogram] = useState()
    const [doExport, setDoExport] = useState(false)
    const [toast, setToast] = useState('')

    const editAudiogram = (audiogram: IAudiogram | string) => {
        setAudiogram(audiogram)
        setDoExport(false)
    }

    const exportAudiogram = (audiogram: IAudiogram) => {
        setAudiogram(audiogram)
        setDoExport(true)
    }

    const backToMe = (message?: string) => {
        setAudiogram('')
        if (typeof message === 'string') {
            setTimeout(() => {
                setToast(message)
            }, 100)         // Allow the render to finish
        }
    }

    if (doExport && audiogram) {
        return (
            <AudiogramExport audiogram={audiogram} onHeaderClick={backToMe}/>
        )
    } else if (audiogram) {
        return (
            <AudiogramEdit audiogram={audiogram} onHeaderClick={backToMe}/>
        )
    } else {
        return (
            <div>
                <AudiogramsList editAudiogram={editAudiogram} exportAudiogram={exportAudiogram} nav={props.nav}/>
                <Toast message={toast}/>
            </div>
        )
    }
}

export default Audiograms
