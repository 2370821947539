import React, {useEffect, useState} from 'react'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton, Snackbar } from '@material-ui/core'

const Toast = (props: any) => {
    const [toast, setToast] = useState('')
    const [toastOpen, setToastOpen] = useState(false)

    useEffect(() => {
        if (props.message) {
            setToast(props.message)
            setToastOpen(true)
        }
    }, [props])

    const toastClose = () => {
        setToastOpen(false)
        setToast('')
    }

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: props.horizontal || 'center'
            }}
            open={toastOpen}
            autoHideDuration={6000}
            onClose={toastClose}
            message={toast}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={toastClose}>
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    )
}

export default Toast
