import {FlagValue} from 'react-unleash-flags'
import * as configModel from './config-model'

const decideFlag = async (flag?: FlagValue): Promise<boolean> => {
  // No flag or not enabled => false
  if (!flag || !flag.enabled) {
    return Promise.resolve(false);
  }

  const flagStrategy = flag.strategies[0]
  if (flagStrategy.name !== 'userWithId') {
    // This is a flag for everybody
    return Promise.resolve(true)
  }

  // This is a flag for certain users
  const allowedUsers = flagStrategy.parameters.userIds.toString().split(',')
  const userId = await configModel.get('userId') || ''
  const isAllowed = allowedUsers.includes(collapseText(userId as string))
  return Promise.resolve(isAllowed)
}

const collapseText = (text: string): string => {
  // Remove whitespace and make lowercase
  return text.replace(/\s/g, '').toLowerCase()
}

export {
  decideFlag,
}

