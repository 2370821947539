import ReactGA from 'react-ga'

export const logCentral = (category: string, action: string, label?: string) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') return

  const _label = `${action}${label ? ': ' + label : ''}`

  ReactGA.event({
    category,
    action,
    label: _label,
  })

}

