// Third party imports
import React, {useState} from 'react'
import {translate as t} from 'react-i18nify'
// import {useFlag} from 'react-unleash-flags'

// Our imports
import Header from '../common/header'
import useStyles from '../styles/sound-tracks-styles'
import {IAudiogram, IAudiogramEditProps} from '../types/audiograms-interfaces'
import Tips from '../help/tips'
import {ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Fab, Typography} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
// import {decideFlag} from '../common/decide-flag'
import shareService from '../common/share-service'
import notificationsService from '../common/notifications-service'
import Toast from '../common/toast'
import IdentityPassword, {ValidatePassword, ValidateUserId} from '../common/identity-password'
import {logCentral} from "../common/logger";

const AudiogramExport = (props: IAudiogramEditProps) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState('')
  const [codedAudiogram, setCodedAudiogram] = useState('')
  const [displayReceiveAudiogram] = useState(true)
  const [toast, setToast] = useState('')
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')

  const audiogramName = (typeof props.audiogram === 'string') ? props.audiogram : (props.audiogram && props.audiogram.name)

  const handleSource = (source: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? source : '')
    if (source === 'export') {
      handleExport()
    }
  }

  const handleExport = () => {
    logEvent('Exporting audiogram', audiogramName)

    // Make the exportable code
    const _codedAudiogram = shareService.encode([props.audiogram as IAudiogram])
    setCodedAudiogram(_codedAudiogram)

    // Copy it to the copy/paste buffer
    navigator.clipboard.writeText(_codedAudiogram)
      .then()
      .catch(error => console.error('clipboard write failed', error))
  }

  const sendAudiogram = async () => {
    setToast('')
    if (!ValidateUserId(userId)) {
      setToast(t('audiograms.identityLonger'))
      return
    }
    if (!ValidatePassword(password)) {
      setToast(t('audiograms.passwordLonger'))
      return
    }

    try {
      logEvent('Sending audiogram', audiogramName)

      await shareService.send(userId, password, props.audiogram as IAudiogram)

      const untilDate = new Date()
      untilDate.setDate(untilDate.getDate() + 6)
      // Notifications are not password protected - because need to display before the password is known
      await notificationsService.send(
        userId,
        '',
        t('audiograms.notificationReceived', {date: untilDate.toLocaleDateString()}))
      props.onHeaderClick(t('audiograms.sentMessage', {user: userId, date: untilDate.toLocaleDateString()}))
    } catch (error) {
      setToast('Error: ' + error.toString())
    }
  }

  // Load the send flag
  /* Turned off for now
  const sendReceiveAudiogram = decideFlag(useFlag('send-receive-audiogram'))
  sendReceiveAudiogram.then(flag => setDisplayReceiveAudiogram(flag))
  */

  const logEvent = (action: string, label?: string) => {
    logCentral('Audiograms', action, label)
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootLargeScreen}>

        <Header classes={classes}
                title={t('audiograms.sendAudiogram')}
                menuOrBack={'back'}
                onButtonClick={props.onHeaderClick}/>

        <br/>

        {!displayReceiveAudiogram ? null :
          <ExpansionPanel className={classes.panelRoot} expanded={expanded === 'send'}
                          onChange={handleSource('send')}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography>{t('audiograms.sendDirectly')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.exportAudiogram}>
                <Typography>{t('audiograms.sendTo', {name: audiogramName as string})}</Typography>
                <IdentityPassword
                  userId={userId}
                  identityLabel={t('audiograms.identityReceiver')}
                  onChangeIdentity={setUserId}
                  onChangePassword={setPassword}
                />
                <Fab variant='extended' color='primary' aria-label='Send'
                     className={classes.fab}
                     onClick={sendAudiogram}>
                  Send
                </Fab>
                <Tips tips={['sendAudiogramDirectly']}/>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        }

        <ExpansionPanel className={classes.panelRoot} expanded={expanded === 'export'}
                        onChange={handleSource('export')}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>{t('audiograms.generateCode')}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.exportAudiogram}>
              <Typography>{t('audiograms.theCodeFor', {name: audiogramName as string})}</Typography>
              <br/>
              {codedAudiogram}
              <Tips tips={['exportAudiogram']}/>
              <Tips tips={['copiedClipboard']}/>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        <Toast message={toast}/>
      </div>
    </div>
  )
}

export default AudiogramExport
