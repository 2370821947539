export const EXAMPLES = {
  exampleLight: {
    exampleId: 'exampleLight',
    name: 'audiograms.exampleLightName',
    points: {
      125: { loss: 35, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 35, setByUser: false, settingNow: false, edgeData: true },
      500: { loss: 35, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 35, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 35, setByUser: true, settingNow: false, edgeData: false },
      2000: { loss: 35, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 35, setByUser: false, settingNow: false, edgeData: true },
      6000: { loss: 35, setByUser: false, settingNow: false, edgeData: true },
      8000: { loss: 35, setByUser: false, settingNow: false, edgeData: true },
    },
  },
  exampleModerate: {
    exampleId: 'exampleModerate',
    name: 'audiograms.exampleModerateName',
    points: {
      125: { loss: 30, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 30, setByUser: false, settingNow: false, edgeData: true },
      500: { loss: 30, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 40, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 50, setByUser: true, settingNow: false, edgeData: false },
      2000: { loss: 60, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
      6000: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
      8000: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
    },
  },
  exampleSevere: {
    exampleId: 'exampleSevere',
    name: 'audiograms.exampleSevereName',
    points: {
      125: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
      500: { loss: 60, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 70, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 80, setByUser: true, settingNow: false, edgeData: false },
      2000: { loss: 90, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 90, setByUser: false, settingNow: false, edgeData: true },
      6000: { loss: 90, setByUser: false, settingNow: false, edgeData: true },
      8000: { loss: 90, setByUser: false, settingNow: false, edgeData: true },
    },
  },
  exampleStarter: {
    exampleId: 'exampleStarter',
    name: 'audiograms.exampleStarterName',
    points: {
      125: { loss: 40, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 40, setByUser: false, settingNow: false, edgeData: true },
      500: { loss: 40, setByUser: false, settingNow: false, edgeData: true },
      1000: { loss: 40, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 50, setByUser: false, settingNow: false, edgeData: false },
      2000: { loss: 60, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
      6000: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
      8000: { loss: 60, setByUser: false, settingNow: false, edgeData: true },
    },
  },
  examplePresbyacusisM50: {
    exampleId: 'examplePresbyacusisM50',
    name: 'audiograms.examplePresbyacusisM50',
    points: {
      125: { loss: 5, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 5, setByUser: true, settingNow: false, edgeData: false },
      500: { loss: 8, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 8, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 9, setByUser: false, settingNow: false, edgeData: false },
      2000: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 17, setByUser: true, settingNow: false, edgeData: false },
      6000: { loss: 23, setByUser: false, settingNow: false, edgeData: false },
      8000: { loss: 25, setByUser: true, settingNow: false, edgeData: false },
    },
  },
  examplePresbyacusisM70: {
    exampleId: 'examplePresbyacusisM70',
    name: 'audiograms.examplePresbyacusisM70',
    points: {
      125: { loss: 8, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 8, setByUser: true, settingNow: false, edgeData: false },
      500: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 15, setByUser: false, settingNow: false, edgeData: false },
      2000: { loss: 20, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 45, setByUser: true, settingNow: false, edgeData: false },
      6000: { loss: 53, setByUser: false, settingNow: false, edgeData: false },
      8000: { loss: 60, setByUser: true, settingNow: false, edgeData: false },
    },
  },
  examplePresbyacusisF50: {
    exampleId: 'examplePresbyacusisF50',
    name: 'audiograms.examplePresbyacusisF50',
    points: {
      125: { loss: 5, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 5, setByUser: true, settingNow: false, edgeData: false },
      500: { loss: 5, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 5, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 7, setByUser: false, settingNow: false, edgeData: false },
      2000: { loss: 8, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      6000: { loss: 13, setByUser: false, settingNow: false, edgeData: false },
      8000: { loss: 15, setByUser: true, settingNow: false, edgeData: false },
    },
  },
  examplePresbyacusisF70: {
    exampleId: 'examplePresbyacusisF70',
    name: 'audiograms.examplePresbyacusisF70',
    points: {
      125: { loss: 9, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 9, setByUser: true, settingNow: false, edgeData: false },
      500: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 12, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 15, setByUser: false, settingNow: false, edgeData: false },
      2000: { loss: 17, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 23, setByUser: true, settingNow: false, edgeData: false },
      6000: { loss: 28, setByUser: false, settingNow: false, edgeData: false },
      8000: { loss: 42, setByUser: true, settingNow: false, edgeData: false },
    },
  },
}
