// Third party imports
import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Fab,
  TextField,
  Typography,
  Slider,
} from '@material-ui/core'
import { useConfirm } from 'material-ui-confirm'
// import {useFlag} from 'react-unleash-flags'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import { translate as t } from 'react-i18nify'

// Our imports
import Header from '../common/header'
import Toast from '../common/toast'
import * as model from './audiograms-model'
import useStyles from '../styles/sound-tracks-styles'
import { IAudiogram, IAudiogramEditProps, IFrequencyMark, Point, Points } from '../types/audiograms-interfaces'
import { AudiogramGraph } from './audiogram-graph'
import Tips from '../help/tips'
import { EXAMPLES } from './audiograms-model'
import * as configModel from '../common/config-model'
import shareService from '../common/share-service'
import { logCentral } from '../common/logger'
import IdentityPassword, { ValidatePassword, ValidateUserId } from '../common/identity-password'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const AudiogramEdit = (props: IAudiogramEditProps) => {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [id, setId] = useState('')
  const [exampleId, setExampleId] = useState('')
  const [title, setTitle] = useState('')
  const [expanded, setExpanded] = useState('')
  const [points, setPoints] = useState<Points>({})
  const [loss, setLoss] = useState(model.defaultLoss)
  const [previousLoss, setPreviousLoss] = useState(0)
  const [frequency, setFrequency] = useState(model.defaultFrequency)
  const [previousFrequency, setPreviousFrequency] = useState(0)
  const [frequencyMark, setFrequencyMark] = useState(model.defaultFrequencyMark)
  const [imported, setImported] = useState('')
  const [toast, setToast] = useState('')
  const [isAudiogramUpdated, setIsAudiogramUpdated] = useState(false)
  const [captureOrSaveFrequency, setCaptureOrSaveFrequency] = useState<'capture' | 'save'>('capture')
  const [captureOrSaveLoss, setCaptureOrSaveLoss] = useState<'capture' | 'save'>('capture')
  const [userId, setUserId] = useState('')
  const [password, setPassword] = useState('')
  const [displayReceiveAudiogram] = useState(true)
  const [tipsAbove, setTipsAbove] = useState<string[]>([])
  const [tipsBelow, setTipsBelow] = useState<string[]>([])
  const [showTipsBelow, setShowTipsBelow] = useState(false)
  const confirm = useConfirm()

  /* Assume the header click is to go back
     param: string | boolean:
          string = message to display
          false = do not confirm if changes are made
  */
  const goBack = (message: string | boolean = '') => {
    if (message === false || !isAudiogramUpdated) {
      props.onHeaderClick('')
      return
    }

    confirm({
      title: t('audiograms.unsavedTitle'),
      description: t('audiograms.unsavedDescription'),
    }).then(() => {
      props.onHeaderClick(message as string)
    })
  }

  const importCodeRef = useRef(null)

  // Load the receive-flag
  /* Turned off for now
  const sendReceiveAudiogram = decideFlag(useFlag('send-receive-audiogram'))
  sendReceiveAudiogram.then(flag => setDisplayReceiveAudiogram(flag))
  */

  // On load get the userId
  useEffect(() => {
    configModel.get('userId').then(userId => {
      setUserId(userId as string)
    })
  }, [])

  // Initial set up of the audiogram
  useEffect(() => {
    if (typeof props.audiogram === 'object') {
      logEvent('Edit an audiogram', props.audiogram.name)

      setEditMode(true)
      setExpanded('create')
      setId(props.audiogram.id || '')
      setExampleId(props.audiogram.exampleId || '')
      setTitle(props.audiogram.name || '')
      setPoints(props.audiogram.points || {})
      setTipsAbove(['addPointAudiogram'])
      setTipsBelow(['addPointsAudiogram'])

      // Find the first point set by user, and use that as the default sliders position
      if (props.audiogram.points) {
        const userFirstFrequency = _.findKey(props.audiogram.points, (p: Point) => p.setByUser)
        if (userFirstFrequency) {
          const frequency = parseInt(userFirstFrequency)
          const userFirstLoss = props.audiogram.points[frequency].loss
          const userFirstFrequencyMark = _.find(model.frequencies, f => f.frequency === frequency) as IFrequencyMark
          setLoss(userFirstLoss)
          setFrequency(frequency)
          setFrequencyMark(userFirstFrequencyMark.value)
        }
      }
    } else {
      logEvent('Start a new audiogram')

      setEditMode(false)
      // We are starting a new audiogram. Add some points to get the user started
      // This doesn't lead to a render - why not ???
      const examples = EXAMPLES.exampleStarter.points
      setPoints(examples)
    }
  }, [props.audiogram])

  // Handle an imported audiogram
  useEffect(() => {
    if (imported) {
      try {
        const importedAudiograms = shareService.decode(imported)

        const importPromises: Promise<IAudiogram>[] = []
        importedAudiograms.forEach(importedAudiogram => {
          importPromises.push(
            model.save({
              id: '',
              name: importedAudiogram.name,
              points: importedAudiogram.points,
            }),
          )

          logEvent('Importing audiogram (through code or after receive)')
        })

        Promise.all(importPromises)
          .then(audiograms => {
            setTimeout(() => {
              props.onHeaderClick(t('audiograms.addedToList', { n: audiograms.length }))
            }, 300) // Allow the render to finish
          })
          .catch(error => {
            setToast(t('audiograms.invalidCode'))
            console.error('A received audiogram appears to be an invalid code', error)
          })
      } catch (error) {
        setToast(t('audiograms.error'))
        console.error('Unknown error while receiving an audiogram', error)
      }
    }
    // avoid the goBack warning
    // eslint-disable-next-line
  }, [imported])

  // Handle a change of frequency, when changed during render or explicitly
  useEffect(() => {
    if (frequency && frequencyMark && frequency !== previousFrequency) {
      const capturing = captureOrSaveFrequency === 'capture'
      handleAddPoint({ setByUser: !capturing, settingNow: capturing, settingFrequency: true })
    }
    // eslint-disable-next-line
  }, [frequency, frequencyMark, captureOrSaveFrequency, points])

  // Handle a change of loss, but only if explicitly moved (slider or target dot)
  useEffect(() => {
    if (typeof loss === 'number' && loss !== previousLoss) {
      const capturing = captureOrSaveLoss === 'capture'
      handleAddPoint({ setByUser: !capturing, settingNow: capturing, settingLoss: true })
    }
    // ignore warnings on other dependencie
    // eslint-disable-next-line
  }, [captureOrSaveLoss])

  const handleAddPoint = (options: {
    setByUser: any
    settingNow: any
    settingFrequency?: boolean
    settingLoss?: boolean
  }) => {
    // Only do something if we're moving
    if (frequency === previousFrequency && loss === previousLoss) return

    const newPoints: Points = { ...points }

    // Add the point on mouse up
    const wasAlreadySet = points[frequency]
    if (options.setByUser) {
      newPoints[frequency] = {
        loss: options.settingLoss ? loss : wasAlreadySet.loss,
        edgeData: false,
        setByUser: true,
        settingNow: false,
      }

      setPreviousFrequency(frequency)
      setPreviousLoss(wasAlreadySet.loss)
    }

    // Preset the point on mouse down
    if (options.settingNow) {
      //Reset the old settingNow point
      const lastSettingNow = _.findKey(points, ['settingNow', true])
      if (options.settingFrequency && lastSettingNow) {
        newPoints[parseInt(lastSettingNow)].settingNow = false
      }

      // Set or update the settingNow point. This is a bit complex... but works :-)
      // First preset the settingNow point to previous value, or, a default
      const wasAlreadySet = points[frequency] || {
        loss,
        edgeData: false,
        setByUser: false,
        settingNow: true,
      }
      // Now create the settingNow point
      newPoints[frequency] = {
        ...wasAlreadySet,
        settingNow: true,
      }
      // Now set the relevant loss
      if (options.settingLoss) {
        newPoints[frequency].loss = loss
      }
      // Now visually set the loss slider accordingly while moving the frequency
      if (options.settingFrequency) {
        setLoss(wasAlreadySet.loss)
      }
    }

    model.addMissingPoints(newPoints)

    // Have changes been made?
    let changesMade = Object.keys(newPoints).find(fString => {
      const f = parseInt(fString)
      if (newPoints[f].setByUser) {
        if (!points[f].setByUser) return true
        if (points[f].loss !== newPoints[f].loss) return true
      }
      return false
    })
    if (changesMade) {
      setIsAudiogramUpdated(Boolean(changesMade))
      setPoints(newPoints)
    }
  }

  const handleSave = () => {
    if (!title) {
      setToast(t('audiograms.giveTitle'))
      return
    }

    if (title.toLowerCase() === 'gwendolynnfilius') {
      logEvent('Adding Gwendolynns audiograms')
      model
        .addGwendolynn()
        .then(() => {
          // Go back and dont confirm
          goBack(false)
        })
        .catch(error => {
          console.error('Error while saving Gwendolynn', error)
        })
    } else {
      logEvent('Saving audiogram', title)

      model
        .save({
          id: id,
          exampleId: exampleId,
          name: title,
          points: points,
        })
        .then(() => {
          // Go back and dont confirm
          goBack(false)
        })
        .catch(error => {
          console.error('Error while saving', error)
        })
    }
  }

  const saveLossValue = (event: ChangeEvent<{}>, lossValue: number | number[]) => {
    setCaptureOrSaveLoss('save')
  }

  const captureLossValue = (event: ChangeEvent<{}>, lossValue: number | number[]) => {
    setCaptureOrSaveLoss('capture')
    setLoss(lossValue as number)
  }

  const saveFrequencyValue = (event: ChangeEvent<{}>, frequencyMark: number | number[]) => {
    setCaptureOrSaveFrequency('save')

    setTipsAbove([])
    const nrOfUserPoints = _.filter(points, (p: Point) => p.setByUser).length
    if (nrOfUserPoints < 2) setTipsAbove(['addPointAudiogram'])
  }

  const captureFrequencyValue = (event: ChangeEvent<{}>, frequencyMark: number | number[]) => {
    const fHz = model.frequencies[`m${frequencyMark}`].frequency
    setFrequency(fHz)
    setFrequencyMark(frequencyMark as number)
    setCaptureOrSaveFrequency('capture')
  }

  const frequencyValueText = (value: number) => {
    const mark = `m${value}`.toString()
    return model.frequencies[mark].label
  }

  const lossValueText = (value: number) => {
    return `${value}dB`
  }

  const handleSource = (source: string) => (event: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? source : '')
    setTitle('')
    setTipsAbove([])
    setTipsBelow([])

    if (source === 'import' && importCodeRef) {
      setTimeout(() => {
        if (importCodeRef && importCodeRef.current) {
          // @ts-ignore - object will not be null
          importCodeRef.current.focus()
        }
      }, 100) // Allow the accordion to open
    } else if (source === 'receive') {
      setTipsBelow(['receiveAudiogram'])
    } else if (source === 'create') {
      setTipsAbove(['addPointAudiogram'])
      setTipsBelow(['addPointsAudiogram'])
    }
  }

  const handleTitle = (title: string) => {
    setIsAudiogramUpdated(true)
    setTitle(title)
  }

  const handleAudiogramClick = (newFrequencyMark: IFrequencyMark, newLoss: number) => {
    // First 'capture' to ensure current dot is set
    setCaptureOrSaveFrequency('capture')
    setCaptureOrSaveLoss('capture')
    if (frequencyMark !== newFrequencyMark.value) {
      setFrequency(newFrequencyMark.frequency)
      setFrequencyMark(newFrequencyMark.value)
    }
    if (loss !== newLoss) setLoss(newLoss)

    // Now 'save'
    setCaptureOrSaveFrequency('save')
    setCaptureOrSaveLoss('save')
  }

  const handleReceive = () => {
    setToast('')

    // Keep the userId
    configModel.set('userId', userId)

    // Do we have sensible data?
    if (!ValidateUserId(userId)) return
    if (!ValidatePassword(password)) return

    // Attempt to retrieve
    shareService
      .retrieve(userId, password)
      .then(audiograms => {
        if (audiograms && audiograms.length > 0) {
          setToast(t('audiograms.foundWait'))
          setImported('')
          setTimeout(() => {
            setToast('')
            setImported(shareService.encode(audiograms))

            logEvent('Retrieved audiogram(s)', audiograms.length.toString())
          }, 3000)
        } else {
          setToast(t('audiograms.receivedInvalid'))
        }
      })
      .catch(() => {
        setToast(t('audiograms.noIdentity'))
      })
  }

  const toggleTips = () => setShowTipsBelow(!showTipsBelow)

  const logEvent = (action: string, label?: string) => {
    logCentral('Audiograms', action, label)
  }

  return (
    <div className={classes.root}>
      <div className={classes.rootLargeScreen}>
        <Header
          classes={classes}
          title={editMode ? t('audiograms.edit') : t('audiograms.add')}
          menuOrBack={'back'}
          onButtonClick={goBack}
        />

        <br />

        {/* Receive an audiogram */}
        {editMode || !displayReceiveAudiogram ? null : (
          <ExpansionPanel
            className={`${classes.panelRoot} ${(expanded === 'create' || expanded === 'import') && classes.hidden}`}
            expanded={expanded === 'receive'}
            onChange={handleSource('receive')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('audiograms.receiveDirectly')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <div className={classes.receiveContainer}>
                <IdentityPassword userId={userId} onChangeIdentity={setUserId} onChangePassword={setPassword} />

                <Tips tips={tipsBelow} />

                <div className={classes.fabContainer}>
                  <Fab
                    variant="extended"
                    color="primary"
                    aria-label="Receive"
                    className={classes.fab}
                    onClick={handleReceive}
                  >
                    {t('general.receive')}
                  </Fab>
                </div>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {/* Import an audiogram */}
        {editMode ? null : (
          <ExpansionPanel
            className={`${classes.panelRoot} ${(expanded === 'receive' || expanded === 'create') && classes.hidden}`}
            expanded={expanded === 'import'}
            onChange={handleSource('import')}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t('audiograms.iHaveCode')}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <textarea
                ref={importCodeRef}
                className={classes.importBox}
                rows={5}
                // placeholder="You should have received a rather long code. Paste that code here"
                placeholder={t('audiograms.haveALongCode')}
                onChange={event => setImported(event.target.value)}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}

        {/* Create/Build an audiogram */}
        <ExpansionPanel
          className={`${classes.panelRoot} ${(expanded === 'receive' || expanded === 'import') && classes.hidden}`}
          expanded={expanded === 'create'}
          onChange={handleSource('create')}
        >
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              {editMode ? t('general.edit') : t('general.build')} {t('audiograms.anAudiogram')}
            </Typography>
          </ExpansionPanelSummary>
          {/* Do not add an <ExpansionPanelDetails> here - it does very weird things with widths */}

          <div className={classes.generalLayout}>
            <div className={classes.editContainer}>
              {/* The audiogram title */}
              <TextField
                id="audiogramTitle"
                label="Title"
                placeholder="Title"
                className={classes.textField}
                margin="normal"
                onChange={event => handleTitle(event.target.value)}
                value={title}
              />

              <Tips tips={tipsAbove} />

              {/* Sliders */}
              <div className={classes.sliders}>
                <Slider
                  className={classes.sliderFreq}
                  min={1}
                  max={model.marksFrequency.length}
                  value={frequencyMark}
                  getAriaValueText={frequencyValueText}
                  valueLabelFormat={frequencyValueText}
                  aria-labelledby="frequency-slider"
                  valueLabelDisplay="on"
                  step={null}
                  marks={model.marksFrequency}
                  onChange={captureFrequencyValue}
                  onChangeCommitted={saveFrequencyValue}
                />
                <Typography className={classes.sliderTitle} color={'textSecondary'}>
                  Frequency
                </Typography>

                <Slider
                  className={classes.sliderLoss}
                  min={model.minLoss}
                  max={model.maxLoss}
                  value={loss}
                  getAriaValueText={lossValueText}
                  valueLabelFormat={lossValueText}
                  aria-labelledby="loss-slider"
                  valueLabelDisplay="on"
                  step={5}
                  marks={model.marksLoss}
                  onChange={captureLossValue}
                  onChangeCommitted={saveLossValue}
                />
                <Typography className={classes.sliderTitle} color={'textSecondary'}>
                  Hearing Loss
                </Typography>
              </div>
            </div>

            {/*******  ************/}
            {/* The audiogram itself */}
            <div className={classes.graphs}>
              <AudiogramGraph
                data={points}
                currentPoint={{ frequency: frequency, loss: loss }}
                graphWidth={600}
                onSelect={handleAudiogramClick}
              />
            </div>
          </div>

          {/* Save button */}
          <div className={classes.fabContainer}>
            <Fab
              id="displayTips"
              color="secondary"
              aria-label="Display tips"
              className={classes.fab}
              onClick={toggleTips}
            >
              <HelpOutlineIcon />
            </Fab>{' '}
            <Fab variant="extended" color="primary" aria-label="Save" className={classes.fab} onClick={handleSave}>
              {t('general.save')}
            </Fab>
          </div>

          {showTipsBelow && <Tips tips={tipsBelow} />}
        </ExpansionPanel>

        <Toast message={toast} />
      </div>
    </div>
  )
}

export default AudiogramEdit
