// Third party imports
import React, {ChangeEvent, useState} from 'react'
import {TextField} from '@material-ui/core'
import { translate as t } from 'react-i18nify'

// Our imports
import useStyles from '../styles/sound-tracks-styles'
import {IIdentityPasswordProps} from '../types/common-interfaces'


const IdentityPassword = (props: IIdentityPasswordProps) => {
  const classes = useStyles()
  const [userId, setUserId] = useState(props.userId)
  const [password, setPassword] = useState('')

  const handleUserId = (event: ChangeEvent) => {
    // @ts-ignore - prop does not exist
    const value = event.target.value
    setUserId(value)
    props.onChangeIdentity(value)
  }

  const handlePassword = (event: ChangeEvent) => {
    // @ts-ignore - prop does not exist
    const value = event.target.value
    setPassword(value)
    props.onChangePassword && props.onChangePassword(value)
  }

  return (
    <>
      <TextField
        id='userId'
        label={props.identityLabel || t('identity.yourIdentity')}
        placeholder='name@email.com'
        className={classes.textField}
        margin='normal'
        onChange={handleUserId}
        value={userId}
      />

      {props.onChangePassword ?
        <TextField
          id='password'
          label={t('identity.password')}
          placeholder={t('identity.password')}
          className={classes.textField}
          margin='normal'
          onChange={handlePassword}
          value={password}
        />
        : null}
    </>
  )
}

export default IdentityPassword

export const ValidateUserId = (userId?: string): boolean => {
  if (!userId) return false
  if (userId.length < 5) return false
  return true
}

export const ValidatePassword = (password?: string): boolean => {
  if (!password) return false
  if (password.length < 8) return false
  return true
}
