import localforage from 'localforage'
import _ from 'lodash'
import shortid from 'shortid'
import { IAudiogram, IFrequencyMarks, Point, Points } from '../types/audiograms-interfaces'
import { localforageConfig } from '../common/localforage-config'
import { translate as t } from 'react-i18nify'
import { EXAMPLES } from './audiograms-examples'
import * as configModel from '../common/config-model'

localforageConfig()
const STORE_PREFIX = 'audiogram.'

// Frequency constants used for the audiogram
const defaultFrequency = 1000
const defaultFrequencyMark = 4
const frequencies: IFrequencyMarks = {
  m1: { value: 1, frequency: 125, label: '125Hz' },
  m2: { value: 2, frequency: 250, label: '250Hz' },
  m3: { value: 3, frequency: 500, label: '500Hz' },
  m4: { value: 4, frequency: 1000, label: '1 kHz' },
  m5: { value: 5, frequency: 1500, label: '1.5 k' },
  m6: { value: 6, frequency: 2000, label: '2 kHz' },
  m7: { value: 7, frequency: 4000, label: '4 kHz' },
  m8: { value: 8, frequency: 6000, label: '6 kHz' },
  m9: { value: 9, frequency: 8000, label: '8 kHz' },
}

const marksFrequency = [
  {
    value: frequencies.m1.value,
  },
  {
    value: frequencies.m2.value,
    label: frequencies.m2.label,
  },
  {
    value: frequencies.m3.value,
  },
  {
    value: frequencies.m4.value,
    label: frequencies.m4.label,
  },
  {
    value: frequencies.m5.value,
  },
  {
    value: frequencies.m6.value,
    label: frequencies.m6.label,
  },
  {
    value: frequencies.m7.value,
  },
  {
    value: frequencies.m8.value,
  },
  {
    value: frequencies.m9.value,
    label: frequencies.m9.label,
  },
]

// Loss constants used for the audiogram
const maxLoss = 120
const minLoss = 0
const defaultLoss = 45
const marksLoss = [
  {
    value: 0,
  },
  {
    value: 20,
    label: '20 dB',
  },
  {
    value: 40,
    label: '40 dB',
  },
  {
    value: 60,
    label: '60 dB',
  },
  {
    value: 80,
    label: '80 dB',
  },
  {
    value: 100,
    label: '100 dB',
  },
  {
    value: 120,
    label: '120 dB',
  },
]

// Save (create and update) to the database
const save = async (audiogram: IAudiogram) => {
  if (!audiogram.id) {
    const id = STORE_PREFIX + shortid.generate()

    return await localforage
      .setItem(id, audiogram)
      .then(() => true)
      .catch(error => error)
  }

  // This is an update, so remove and re-save with same id
  if (audiogram.id) {
    const originalId = audiogram.id
    await localforage.removeItem(originalId)
    delete audiogram.id

    return await localforage
      .setItem(originalId, audiogram)
      .then(() => true)
      .catch(error => error)
  }
}

// Remove from the database
const remove = async (audiogram: IAudiogram) => {
  if (!audiogram || !audiogram.id) return

  return await localforage
    .removeItem(audiogram.id)
    .then(() => true)
    .catch(error => error)
}

// Get all audiograms from the database
const getAllAudiograms = async () => {
  let collected: IAudiogram[] = []

  await migrationAddPresbyacusisExamples()

  return localforage
    .iterate((audiogram: IAudiogram, id) => {
      let collector: IAudiogram = {}
      if (id.slice(0, STORE_PREFIX.length) === STORE_PREFIX) {
        collector.id = id
        collector.exampleId = audiogram.exampleId
        collector.name = audiogram.name
        collector.points = audiogram.points
        collected.push(collector)
      }
    })
    .then(() => {
      // Add example audiograms if required
      let addPromise = Promise.resolve()
      if (collected.length === 0) {
        addPromise = addExamples()
      }

      return Promise.all([addPromise]).then(responses => {
        // Sort all audiograms by name
        collected.sort((a, b) => {
          if (!a || !b || !a.name || !b.name) return 0
          return a.name.localeCompare(b.name)
        })
        return collected
      })
    })
}

const migrationAddPresbyacusisExamples = async () => {
  // check if addPresbyacusis migration done
  const hasMigrated = await configModel.get('hasAddedPresbyacusis')
  if (hasMigrated) return

  // If there is at lease 1 ag, add the new presbyacusis examples
  const currentKeys = await localforage.keys()
  const currentAgs = currentKeys.filter(k => k.includes(STORE_PREFIX))
  if (!currentAgs.length) {
    // No ags, so all examples will be added, so do nothing here
    return
  }

  // Check if prebsyacusis examples already there
  let haveOne = false
  for (const agKey of currentAgs) {
    if (haveOne) break
    const ag: IAudiogram = await localforage.getItem(agKey)
    if (ag.exampleId && ag.exampleId.includes('examplePresbyacusis')) {
      haveOne = true
      break
    }
  }
  if (haveOne) return

  // Add them + set flag
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisM50.exampleId,
    name: t(EXAMPLES.examplePresbyacusisM50.name),
    points: EXAMPLES.examplePresbyacusisM50.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisM70.exampleId,
    name: t(EXAMPLES.examplePresbyacusisM70.name),
    points: EXAMPLES.examplePresbyacusisM70.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisF50.exampleId,
    name: t(EXAMPLES.examplePresbyacusisF50.name),
    points: EXAMPLES.examplePresbyacusisF50.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisF70.exampleId,
    name: t(EXAMPLES.examplePresbyacusisF70.name),
    points: EXAMPLES.examplePresbyacusisF70.points,
  })
  await configModel.set('hasAddedPresbyacusis', true)
}

// Provide a value for non-filled frequencies
const addMissingPoints = (points: Points) => {
  let firstUserKey: number = parseInt(_.findKey(points, p => p.setByUser || p.settingNow) || '')
  let lastUserKey: number = parseInt(_.findLastKey(points, p => p.setByUser || p.settingNow) || '')

  let firstUserPoint: Point = { ...points[firstUserKey], frequency: firstUserKey }
  let lastUserPoint: Point = { ...points[lastUserKey], frequency: lastUserKey }
  let previousUserPoint: Point = firstUserPoint
  let nextUserPoint: Point = lastUserPoint

  let freq: number
  let loss: number
  let edgeData: boolean
  let setThisPoint = true

  const frequenciesValues = Object.keys(frequencies)
  frequenciesValues.map((f, index) => {
    freq = frequencies[f].frequency

    if (!points[freq] || !points[freq].setByUser || !points[freq].settingNow) {
      // This point needs to be (re)generated

      if (freq < (firstUserPoint.frequency || 0)) {
        // Left of first point
        loss = firstUserPoint.loss
        edgeData = true
      } else if (freq === firstUserPoint.frequency) {
        // Is the first point
        loss = firstUserPoint.loss
        edgeData = false
      } else if (freq > (lastUserPoint.frequency || 99999)) {
        // Right of last point
        loss = lastUserPoint.loss
        edgeData = true
      } else if (freq === lastUserPoint.frequency) {
        // Is the last point
        loss = lastUserPoint.loss
        edgeData = false
      } else {
        // This point is between the first and last user point
        if (points[freq].setByUser) {
          // Was set by a user, so leave it alone
          previousUserPoint = points[freq]
          setThisPoint = false
        } else {
          // Find the next point that is set by user
          let nextFound: boolean = false
          frequenciesValues.map(f2 => {
            if (f2 <= f || nextFound) return null
            const freq2 = frequencies[f2].frequency
            if (points[freq2] && (points[freq2].setByUser || points[freq2].settingNow)) {
              nextUserPoint = { ...points[freq2], frequency: freq2 }
              nextFound = true
            }

            return null // Only required to please typescript
          })

          // Average between 2 user points
          loss = (previousUserPoint.loss + nextUserPoint.loss) / 2

          // Linear extrapolation between 2 user points
          if (previousUserPoint.frequency && nextUserPoint.frequency) {
            loss = Math.round(
              ((nextUserPoint.loss - previousUserPoint.loss) * (freq - previousUserPoint.frequency)) /
                (nextUserPoint.frequency - previousUserPoint.frequency) +
                previousUserPoint.loss,
            )
          }

          // Logarithmic average
          // if (previousUserPoint.frequency === nextUserPoint.frequency) {
          //     loss = points[freq].loss
          // } else if (previousUserPoint.frequency && nextUserPoint.frequency) {
          //     // Logarithmic extrapolation between 2 user points
          //     loss = Math.round(
          //         (nextUserPoint.loss - previousUserPoint.loss) *
          //         (Math.log10(freq) - Math.log10(previousUserPoint.frequency)) /
          //         (Math.log10(nextUserPoint.frequency) - Math.log10(previousUserPoint.frequency))
          //         + previousUserPoint.loss
          //     )
          // }
        }

        edgeData = false
      }

      if (setThisPoint) {
        points[frequencies[f].frequency] = {
          loss: loss || 0,
          setByUser: (points[freq] && points[freq].setByUser) || false,
          settingNow: (points[freq] && points[freq].settingNow) || false,
          edgeData: edgeData,
        }
      }
      setThisPoint = true
    }

    if (points[freq].setByUser || points[freq].settingNow) {
      previousUserPoint = { ...points[freq], frequency: freq }
    }

    return null // Only required to please typescript
  })
}

// Add the example audiogram
const addExamples = async () => {
  await save({
    id: '',
    exampleId: EXAMPLES.exampleLight.exampleId,
    name: t(EXAMPLES.exampleLight.name),
    points: EXAMPLES.exampleLight.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.exampleModerate.exampleId,
    name: t(EXAMPLES.exampleModerate.name),
    points: EXAMPLES.exampleModerate.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.exampleSevere.exampleId,
    name: t(EXAMPLES.exampleSevere.name),
    points: EXAMPLES.exampleSevere.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisM50.exampleId,
    name: t(EXAMPLES.examplePresbyacusisM50.name),
    points: EXAMPLES.examplePresbyacusisM50.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisM70.exampleId,
    name: t(EXAMPLES.examplePresbyacusisM70.name),
    points: EXAMPLES.examplePresbyacusisM70.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisF50.exampleId,
    name: t(EXAMPLES.examplePresbyacusisF50.name),
    points: EXAMPLES.examplePresbyacusisF50.points,
  })
  await save({
    id: '',
    exampleId: EXAMPLES.examplePresbyacusisF70.exampleId,
    name: t(EXAMPLES.examplePresbyacusisF70.name),
    points: EXAMPLES.examplePresbyacusisF70.points,
  })

  await configModel.remove('hasAddedPresbyacusis')
}

// Replace all examples - required when language changes
const replaceExamples = async () => {
  // Delete current examples
  const audiograms = await getAllAudiograms()
  const examples = audiograms.filter(audiogram => Boolean(audiogram.exampleId))
  for (const example of examples) {
    remove(example)
  }

  await addExamples()
  return Promise.resolve()
}

const addGwendolynn = async () => {
  let example

  // // Example Gwen left ear 2018
  // example = {
  //     name: 'Gwendolynn left ear 2018',
  //     points: {
  //         125: {loss: 15, setByUser: false, settingNow: false, edgeData: true},
  //         250: {loss: 15, setByUser: true, settingNow: false, edgeData: false},
  //         500: {loss: 0, setByUser: true, settingNow: false, edgeData: false},
  //         1000: {loss: 15, setByUser: true, settingNow: false, edgeData: false},
  //         1500: {loss: 30, setByUser: true, settingNow: false, edgeData: false},
  //         2000: {loss: 70, setByUser: true, settingNow: false, edgeData: false},
  //         4000: {loss: 85, setByUser: true, settingNow: false, edgeData: false},
  //         6000: {loss: 95, setByUser: true, settingNow: false, edgeData: false},
  //         8000: {loss: 105, setByUser: true, settingNow: false, edgeData: false},
  //     },
  // }
  // await save({
  //     id: '',
  //     name: example.name,
  //     points: example.points,
  // })
  // // Example Gwen right ear 2018
  // example = {
  //     name: 'Gwendolynn right ear 2018',
  //     points: {
  //         125: {loss: 10, setByUser: false, settingNow: false, edgeData: true},
  //         250: {loss: 10, setByUser: true, settingNow: false, edgeData: false},
  //         500: {loss: 25, setByUser: true, settingNow: false, edgeData: false},
  //         1000: {loss: 80, setByUser: true, settingNow: false, edgeData: false},
  //         1500: {loss: 110, setByUser: true, settingNow: false, edgeData: false},
  //         2000: {loss: 110, setByUser: true, settingNow: false, edgeData: false},
  //         4000: {loss: 110, setByUser: true, settingNow: false, edgeData: false},
  //         6000: {loss: 110, setByUser: true, settingNow: false, edgeData: false},
  //         8000: {loss: 105, setByUser: true, settingNow: false, edgeData: false},
  //     },
  // }
  // await save({
  //     id: '',
  //     name: example.name,
  //     points: example.points,
  // })
  // Example Gwen left ear 2020
  example = {
    name: 'Gwendolynn left ear 2020',
    points: {
      125: { loss: 10, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      500: { loss: 0, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 10, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 25, setByUser: true, settingNow: false, edgeData: false },
      2000: { loss: 70, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 95, setByUser: true, settingNow: false, edgeData: false },
      6000: { loss: 90, setByUser: true, settingNow: false, edgeData: false },
      8000: { loss: 105, setByUser: true, settingNow: false, edgeData: false },
    },
  }
  await save({
    id: '',
    name: example.name,
    points: example.points,
  })

  // Example Gwen right ear 2020
  example = {
    name: 'Gwendolynn right ear 2020',
    points: {
      125: { loss: 10, setByUser: false, settingNow: false, edgeData: true },
      250: { loss: 20, setByUser: true, settingNow: false, edgeData: false },
      500: { loss: 25, setByUser: true, settingNow: false, edgeData: false },
      1000: { loss: 75, setByUser: true, settingNow: false, edgeData: false },
      1500: { loss: 95, setByUser: true, settingNow: false, edgeData: false },
      2000: { loss: 105, setByUser: true, settingNow: false, edgeData: false },
      4000: { loss: 110, setByUser: true, settingNow: false, edgeData: false },
      6000: { loss: 115, setByUser: true, settingNow: false, edgeData: false },
      8000: { loss: 110, setByUser: true, settingNow: false, edgeData: false },
    },
  }
  await save({
    id: '',
    name: example.name,
    points: example.points,
  })
}

const resetExample = async (audiogram: IAudiogram) => {
  // @ts-ignore - the warning about index type
  const originalPoints = EXAMPLES[audiogram.exampleId].points
  await save({
    id: audiogram.id,
    exampleId: audiogram.exampleId,
    name: audiogram.name,
    points: originalPoints,
  })
}

export {
  EXAMPLES,
  frequencies,
  marksFrequency,
  defaultFrequency,
  defaultFrequencyMark,
  marksLoss,
  defaultLoss,
  minLoss,
  maxLoss,
  save,
  remove,
  getAllAudiograms,
  addMissingPoints,
  addExamples,
  replaceExamples,
  resetExample,
  addGwendolynn,
}
