import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    identityContainer: {
      backgroundColor: 'white',
      margin: theme.spacing(1)
    },
    identityText: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      color: 'grey',
      fontStyle: 'italic',
      fontSize: 'smaller',
    },
    '@media (min-width: 1224px)': {
    },
  })
})

export default useStyles
