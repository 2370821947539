import React from 'react'
import ReactDOM from 'react-dom'
import {translate as t} from 'react-i18nify'

import App from './App'
import './index.css'
import * as serviceWorker from './serviceWorker'
import * as configModel from './common/config-model'

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.register({
    onUpdate: (registration) => {
        configModel.set('updateIsAvailable', true)
        alert(t('general.wantToUpdate'))
    }
})
